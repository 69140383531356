import React, { useRef, useContext } from 'react';
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
} from '@chakra-ui/core';
import { IconSettings } from '../../components/common/icons';
import { useForm } from 'react-hook-form';

import { EventContext } from '../context/EventContextProvider';
import { PrepareContext } from '../context/PrepareContextProvider';
import FilterCategory from './category';
import FilterDate from './date';
import FilterStatus from './status';
import FilterLocation from './location';

const FilterDrawer = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnFilter = useRef();
    const { listEvents } = useContext(EventContext);
    const { addQueryInput } = useContext(PrepareContext);
    const { handleSubmit } = useForm({});

    const onSubmit = () => {
        addQueryInput(listEvents);
        onClose();
    };
    return (
        <>
            <Button
                ref={btnFilter}
                onClick={onOpen}
                variant='ghost'
                rightIcon={<IconSettings boxSize={4} color='brand.700' />}
            >
                Filtra eventi
            </Button>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                initialFocusRef={btnFilter}
                trapFocus={true}
                blockScrollOnMount={false}
            >
                <DrawerOverlay zIndex='100000' />
                <DrawerContent zIndex='10000000'>
                    <DrawerCloseButton />
                    <DrawerHeader>Filters</DrawerHeader>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ overflow: 'auto' }}>
                        <DrawerBody>
                            <FilterStatus />
                            {/*<FilterLocation />*/}
                            <FilterDate />
                            <FilterCategory />
                        </DrawerBody>

                        <DrawerFooter
                            justifyContent='flex-start'
                            style={{ position: 'sticky', bottom: '0', background: '#fff' }}
                        >
                            <Button type='submit' mr='4' variant='brand'>
                                Applica
                            </Button>
                            <Button variant='outline' variant='grey' onClick={onClose}>
                                Cancella
                            </Button>
                        </DrawerFooter>
                    </form>
                </DrawerContent>
            </Drawer>
        </>
    );
};

FilterDrawer.propTypes = {};

export default FilterDrawer;
