import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Heading, Text, Image, Box, Link, Badge, Wrap } from '@chakra-ui/core';

import VideoThumb from '../../components/common/videoThumb';
import Moment from 'react-moment';
import 'moment/locale/it';

const MainSection = ({ event }) => {
    return (
        <Box>
            <Wrap spacing='12px' align='center' mb='2'>
                <Badge variant='brandDarkOutline' maxW='32ch' isTruncated>
                    {event.eventCategory.name}
                </Badge>
                {event.tag && (
                    <Text
                        as='span'
                        fontSize='sm'
                        fontWeight='500'
                        textTransform='uppercase'
                        color='brand.700'
                    >
                        #{event.tag}
                    </Text>
                )}
            </Wrap>
            <Heading as='h1' size='2xl' color='brand.700' mb={[4, 4, 6]}>
                {event.name}
            </Heading>

            {event.status !== 3 && (
                <Wrap spacing='12px' align='center' mb={[4, 4, 6, 10]}>
                    <Badge fontSize='1.4em' variant='brandDark' textTransform='uppercase'>
                        <Text as='span' color='grey.100'>
                            <Moment unix format='dddd' local locale='it'>
                                {event.startAtTimestamp}
                            </Moment>
                        </Text>{' '}
                        <Moment unix format='D/MM'>
                            {event.startAtTimestamp}
                        </Moment>
                    </Badge>
                    <Text
                        as='p'
                        fontFamily='heading'
                        fontSize='2xl'
                        fontWeight='500'
                        color='brand.700'
                    >
                        <Moment unix format='HH:mm'>
                            {event.startAtTimestamp}
                        </Moment>
                        -
                        <Moment unix format='HH:mm'>
                            {event.endAtTimestamp}
                        </Moment>
                    </Text>
                </Wrap>
            )}
            {/* if it's live */}
            {event.status === 2 && (
                <Box mb={[4, 4, 6, 10]}>
                    <VideoThumb
                        isLive
                        eventId={event.id}
                        imgUrl={event.coverPhotoUrl}
                        videoUrl={event.liveVideoUrl}
                        id={event.id}
                    />
                </Box>
            )}
            {event.status === 3 && (
                <Box mb={[4, 4, 6, 10]}>
                    <VideoThumb
                        eventId={event.id}
                        imgUrl={event.coverPhotoUrl}
                        videoUrl={event.liveVideoUrl}
                        id={event.id}
                    />
                </Box>
            )}
            {/* END - if it's live */}
            {/* if it will happen */}
            {event.status === 1 && (
                <Box mb={[4, 4, 6, 10]}>
                    {/* <VideoThumb
                        isPhoto
                        eventId={event.id}
                        videoUrl={event.eventVideoUrl}
                        imgUrl={event.coverPhotoUrl}
                    /> */}
                    <Image
                        src={event.coverPhotoUrl}
                        alt={event.name}
                        minW='100%'
                        style={{ borderRadius: '4px' }}
                    />
                </Box>
            )}
            {/* END - if it will happen */}
            <Box dangerouslySetInnerHTML={{ __html: event.description }} className='mainContent' />
        </Box>
    );
};
export default MainSection;
