import React from 'react';
import { Box, Text, SimpleGrid, Link, Spinner } from '@chakra-ui/core';
import { useContactPersonsQuery } from '../queries/contactPersons';

const ContactPerPerson = () => {
    const contacts = useContactPersonsQuery();
    if (contacts.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.700' />
            </Box>
        );
    if (contacts.error) return <p>ERROR</p>;
    if (!contacts.data) return <p>Not found</p>;
    return (
        <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={10}>
            {contacts.data.getContactPersons.map((contact, index) => (
                <Box key={contact.id} w='100%' h='100%'>
                    <Text fontSize='18' textTransform='uppercase' color='brand.700' mt='4'>
                        <strong>{contact.name}</strong>
                    </Text>
                    <Box fontSize='sm'>
                        <Text fontSize='lg' mt='0'>
                            {contact.description}
                        </Text>
                        <Box d='inline-block' mt='4'>
                            <Link mt='4' href={`mailto:${contact.email}`} color='brand.700'>
                                {contact.email}
                            </Link>
                        </Box>
                        <Text mt='4'>{contact.address}</Text>
                        <Text mt='0'>{contact.phone}</Text>
                    </Box>
                </Box>
            ))}
        </SimpleGrid>
    );
};

export default ContactPerPerson;
