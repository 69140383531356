import { extendTheme } from '@chakra-ui/core';

const breakpoints = ['375px', '768px', '1024px', '1366px'];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const customTheme = extendTheme({
    styles: {
        global: {
            'html, body': {
                background: 'grey.50',
            },
            'body.has-padding': {
                paddingTop: '122px',
            },
        },
    },
    fonts: {
        body: `"Roboto", sans-serif`,
        heading: `"Oswald", sans-serif`,
        mono: `"source-code-pro", Menlo, Monaco, Consolas, "Courier New", monospace;`,
    },
    colors: {
        // brand: {
        // 50: '#ffe2f9',
        // 100: '#ffb1e2',
        // 200: '#ff7ecc',
        // 300: '#ff4cb6',
        // 400: '#ff1aa2',
        // 500: '#53225d',
        // 600: '#b4006a',
        // 700: '#8900a3',
        // 800: '#50002e',
        // 900: '#200012',
        // },
        brand: {
            50: '#f6eafd',
            100: '#dec6ec',
            200: '#c8a2dd',
            300: '#b67dce',
            400: '#a458bf',
            500: '#8d3fa6',
            600: '#713181',
            700: '#53225d', //#52225c
            800: '#301439',
            900: '#120517',
        },

        brown: {
            100: '#e7dfdd',
            500: '#cac2c0',
        },
        grey: {
            50: '#f0f0f0',
            100: '#DEDFE0',
            200: '#bfbfbf',
            300: '#a6a6a6',
            400: '#8c8c8c',
            500: '#737373',
            600: '#b4006a',
            700: '#8900a3 ',
            800: '#262626',
            900: '#0d0d0d',
        },
        error: {
            50: '#feebef',
            100: '#feced4',
            200: '#ee9c9e',
            300: '#e47679',
            400: '#ef5758',
            500: '#f4473f',
            600: '#e53e3e',
            700: '#d33538',
            800: '#c62e31',
            900: '#b62425',
        },
        warning: {
            // 800
            50: '#f4e4f5',
            100: '#e3bce7',
            200: '#d18ed8',
            300: '#bd60c8',
            400: '#af3bbc',
            500: '#a009b0',
            600: '#9207aa',
            700: '#7f03a3',
            800: '#6d009c',
            900: '#4c0090',
        },
    },
    breakpoints,
    components: {
        Input: {
            variants: {
                main: {
                    field: {
                        borderRadius: 'md',
                        border: '1px solid #999',
                        bg: '#fff',
                        _hover: {
                            borderColor: '#8d3fa6',
                        },
                        _focus: {
                            borderColor: '#8d3fa6',
                            boxShadow: '0 0 0 1px #8d3fa6',
                        },
                    },
                },
            },
            defaultProps: {
                size: 'lg',
                variant: 'main',
            },
        },
        Textarea: {
            variants: {
                main: {
                    borderRadius: 'md',
                    border: '1px solid #999',
                    bg: '#fff',
                    _hover: {
                        borderColor: '#8d3fa6',
                    },
                    _focus: {
                        borderColor: '#8d3fa6',
                        boxShadow: '0 0 0 1px #8d3fa6',
                    },
                },
            },
            defaultProps: {
                size: 'lg',
                variant: 'main',
            },
        },
        Checkbox: {
            variants: {
                main: {
                    control: {
                        border: '2px solid #8d3fa6',
                        borderColor: '#8d3fa6',
                        _focus: {
                            borderColor: '#8d3fa6',
                            boxShadow: '0 0 0 1px #8d3fa6',
                        },
                    },
                    label: {
                        fontSize: 'sm',
                    },
                },
            },
            defaultProps: {
                variant: 'main',
                size: 'md',
                colorScheme: 'brand',
            },
        },
        Button: {
            baseStyle: {
                fontWeight: 'bold',
                borderRadius: '4px',
                textTransform: 'uppercase',
            },
            sizes: {
                xsm: {
                    px: '8px',
                    py: '5px',
                    fontSize: '14px',
                },
                sm: {
                    px: '18px',
                    py: '12px',
                    fontSize: '16px',
                },
                lg: {
                    px: '24px',
                    py: '16px',
                    fontSize: '18px',
                },
            },
            variants: {
                brand: {
                    bg: '#53225d',
                    border: '1px solid #53225d',
                    color: 'white',
                    _hover: { borderColor: '#120517', bg: '#120517' },
                },
                brandDark: {
                    bg: '#8900a3',
                    border: '1px solid #8900a3',
                    color: 'white',
                    _hover: { borderColor: '#120517', bg: '#120517' },
                },
                ghost: {
                    bg: 'transparent',
                    border: 'none',
                    color: '#53225d',
                    fontFamily: `"Oswald", sans-serif`,
                    fontWeight: '400',
                    fontSize: '18px',
                    _hover: { bg: 'rgba(0,0,0,0.05)' },
                },
                white: {
                    bg: 'white',
                    border: '1px solid #fff',
                    color: 'black',
                    _hover: { borderColor: 'white', bg: 'rgba(255,255,255,.7)' },
                },
                whiteOutline: {
                    bg: '#53225d',
                    border: '1px solid #fff',
                    color: 'white',
                    _hover: { bg: '#8d3fa6' },
                },
                grey: {
                    bg: '#dedfe0',
                    border: '1px solid #dedfe0',
                    color: '#8900a3',
                    _hover: { borderColor: '#f0f0f0', bg: '#f0f0f0' },
                },
            },
            defaultProps: {
                size: 'sm',
                variant: 'solid',
            },
        },
        Tabs: {
            defaultProps: {
                colorScheme: 'brand',
            },
        },
        Badge: {
            baseStyle: {
                fontSize: '14px',
                borderRadius: '4px',
                fontWeight: 'normal',
                fontFamily: 'heading',
                textTransform: 'uppercase',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '32ch',
            },
            variants: {
                brand: {
                    bg: '#53225d',
                    padding: '2px 7px 4px',
                    border: '1px solid #53225d',
                    color: 'white',
                },
                brandLarge: {
                    bg: '#53225d',
                    padding: '4px 20px 5px',
                    border: '1px solid #53225d',
                    color: 'white',
                    fontSize: '24px',
                },
                brandOutline: {
                    bg: 'white',
                    padding: '2px 7px 4px',
                    border: '1px solid #53225d',
                    color: '#53225d',
                },
                brandDark: {
                    bg: '#8900a3',
                    padding: '2px 7px 4px',
                    border: '1px solid #8900a3',
                    color: '#fff',
                },
                brandDarkOutline: {
                    bg: 'white',
                    padding: '2px 7px 4px',
                    border: '1px solid #8900a3',
                    color: '#8900a3',
                },
                brandDarkOutlineBig: {
                    bg: 'white',
                    padding: '12px 32px',
                    border: '2px solid #8900a3',
                    color: '#8900a3',
                },
                whiteOutline: {
                    bg: 'transparent',
                    padding: '2px 7px 4px',
                    border: '1px solid #fff',
                    color: '#fff',
                },
                gold: {
                    bg: '#fff',
                    padding: '2px 7px 4px',
                    border: '1px solid #cc9300',
                    color: '#cc9300',
                },
                silver: {
                    bg: '#fff',
                    padding: '2px 7px 4px',
                    border: '1px solid #999999',
                    color: '#999999',
                },
            },
        },
        FormLabel: {
            baseStyle: {
                fontSize: '14px',
                letterSpacing: '0.1em',
                fontWeight: '500',
                color: '#53225d',
                textTransform: 'uppercase',
                marginBottom: '8px',
            },
        },
        Heading: {
            sizes: {
                mdf: {
                    fontSize: '1.5rem',
                },
            },
            baseStyle: {
                fontWeight: 'normal',
                // textTransform: 'uppercase',
            },
        },
    },
});
