import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Container,
    Heading,
    Text,
    Box,
    Link,
    Badge,
    Wrap,
    Grid,
    HStack,
    VStack,
    StackDivider,
    Avatar,
    ButtonGroup,
    IconButton,
    AspectRatio,
} from '@chakra-ui/core';

import { IconDownload } from '../components/common/icons';

import ProgramsContactform from './components/programsContactform';

import ScrollToTop from '../common/scrollToTop';

const ProgramsLive = () => {
    return (
        <Box bg='black' color='white'>
            <ScrollToTop />
            <Container maxW='1312px' py={[4, 4, 8, 12]}>
                <Grid
                    templateColumns={{
                        base: '1fr',
                        sm: '1fr',
                        md: '2fr 1fr',
                        lg: '2.5fr 1fr',
                    }}
                    gap={{
                        base: 4,
                        sm: 4,
                        md: 6,
                        lg: 10,
                    }}
                >
                    <Box>
                        <Box mb={[4, 4, 6, 10]}>
                            <AspectRatio
                                // ratio={16 / 9}
                                ratio={1.7777}
                                borderRadius='md'
                                style={{ overflow: 'hidden' }}
                            >
                                <iframe
                                    title='xxxxxx'
                                    allowFullScreen
                                    src='https://www.youtube.com/embed/36YnV9STBqc?autoplay=1'
                                    allow='autoplay'
                                />
                            </AspectRatio>
                        </Box>
                        {/* END - if it's live */}
                        <Wrap spacing='12px' align='center' mb='2'>
                            <Badge variant='brandDarkOutline'>Planaria</Badge>
                            <Text
                                as='span'
                                fontSize='sm'
                                fontWeight='500'
                                textTransform='uppercase'
                            >
                                #Opening
                            </Text>
                        </Wrap>
                        <Heading as='h1' size='2xl' color='brand.700' mb={[4, 4, 6]}>
                            La governance del SSN tra accentramento e decentramento
                        </Heading>
                        <Wrap spacing='12px' align='center' mb={[4, 4, 6, 10]}>
                            <Badge fontSize='1.4em' variant='brandDark' textTransform='uppercase'>
                                <Text as='span' color='grey.100'>
                                    Giovedi
                                </Text>{' '}
                                01/10
                            </Badge>
                            <Text as='p' fontFamily='heading' fontSize='2xl' fontWeight='500'>
                                10:30 – 12:00
                            </Text>
                        </Wrap>
                        <Box className='mainContent' mb={[4, 4, 10]}>
                            <p>
                                La pandemia COVID-19 o Severe Acute Respiratory Syndrome CoronaVirus
                                2 (SARS-CoV-2) ha posto molte sfide e ha costretto, potremmo dire
                                finalmente, a ripensare i modelli assistenziali, a partire dalla
                                medicina generale fino all’assistenza ai pazienti fragili, dall’uso
                                dei dati all’impatto su sistemi regionali frammentati, soprattutto
                                dal punto di vista digitale.
                            </p>
                        </Box>
                        <Box>
                            <Heading as='h3' size='md' mb='4' textTransform='uppercase'>
                                Responsabili Scientifici
                            </Heading>
                            <Wrap spacing='32px' align='center'>
                                <Wrap spacing='12px' align='center'>
                                    <Avatar
                                        size='md'
                                        name='Milena Vainieri'
                                        src='https://randomuser.me/api/portraits/thumb/women/75.jpg'
                                    />
                                    <Text as='p' color='brand.700'>
                                        Milena Vainieri
                                    </Text>
                                </Wrap>
                                <Wrap spacing='12px' align='center'>
                                    <Avatar
                                        size='md'
                                        name='Andrea Vannucci'
                                        src='https://randomuser.me/api/portraits/thumb/men/75.jpg'
                                    />
                                    <Text as='p' color='brand.700'>
                                        Andrea Vannucci
                                    </Text>
                                </Wrap>
                            </Wrap>
                        </Box>
                    </Box>
                    {/* Right column */}
                    <Box>
                        {/* <AspectRatio
                            bg='white'
                            borderRadius='md'
                            mb='6'
                            ratio={[1.3, 1.3, 0.89, 0.71]}
                            borderRadius='md'
                            style={{ overflow: 'hidden' }}
                        >
                            <iframe
                                title='xxxxxx'
                                src='https://www.youtube.com/live_chat?v=36YnV9STBqc&embed_domain=fss.nisyncdev.com'
                            />
                        </AspectRatio> */}
                        <Box bg='white' borderRadius='md' mb='6' p='4'>
                            <ProgramsContactform sender={'Valentino Rossi'} programID={123456789} />
                        </Box>
                        <Box bg='white' borderRadius='md' mb='6' p='4'>
                            <Heading
                                as='h3'
                                size='md'
                                mb='4'
                                textTransform='uppercase'
                                color='black'
                            >
                                Scarica file
                            </Heading>
                            <VStack
                                divider={<StackDivider borderColor='gray.200' />}
                                spacing={4}
                                align='stretch'
                                fontSize='lg'
                                color='brand.700'
                            >
                                <HStack spacing='12px' align='center'>
                                    <ButtonGroup
                                        as={RouterLink}
                                        to='/xxxxx'
                                        size='md'
                                        variant='grey'
                                    >
                                        <IconButton aria-label='Download' icon={<IconDownload />} />
                                    </ButtonGroup>
                                    <Link as={RouterLink} to='/xxxxx' lineHeight='1.3'>
                                        <Text as='div' fontSize='lg'>
                                            catalogo farmdfgdfdfgdfgdfg df df gdfgfdg acia.pdf
                                        </Text>
                                        <Text as='div' color='brand.700' fontSize='sm'>
                                            5.62 Mb
                                        </Text>
                                    </Link>
                                </HStack>
                                <HStack spacing='12px' align='center'>
                                    <ButtonGroup
                                        as={RouterLink}
                                        to='/xxxxx'
                                        size='md'
                                        variant='grey'
                                    >
                                        <IconButton aria-label='Download' icon={<IconDownload />} />
                                    </ButtonGroup>
                                    <Link as={RouterLink} to='/xxxxx' lineHeight='1.3'>
                                        <Text as='div' fontSize='lg'>
                                            catalogo farmacia.pdf
                                        </Text>
                                        <Text as='div' color='brand.700' fontSize='sm'>
                                            5.62 Mb
                                        </Text>
                                    </Link>
                                </HStack>
                                <HStack spacing='12px' align='center'>
                                    <ButtonGroup
                                        as={RouterLink}
                                        to='/xxxxx'
                                        size='md'
                                        variant='grey'
                                    >
                                        <IconButton aria-label='Download' icon={<IconDownload />} />
                                    </ButtonGroup>
                                    <Link as={RouterLink} to='/xxxxx' lineHeight='1.3'>
                                        <Text as='div' fontSize='lg'>
                                            catalogo farmacia.pdf
                                        </Text>
                                        <Text as='div' color='brand.700' fontSize='sm'>
                                            5.62 Mb
                                        </Text>
                                    </Link>
                                </HStack>
                            </VStack>
                        </Box>
                    </Box>
                </Grid>
            </Container>
        </Box>
    );
};

export default ProgramsLive;
