import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ScrollToTop from '../common/scrollToTop';

import {
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    TwitterIcon,
    FacebookIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon,
} from 'react-share';

import {
    Container,
    Heading,
    Image,
    Text,
    Link,
    Grid,
    Box,
    VStack,
    SimpleGrid,
} from '@chakra-ui/core';

import { ThumbDate } from '../components/common/styles';
import { BlogPostCover } from './styles';
import BlogCard from './components/blogCard';

const BlogSingle = () => {
    return (
        <>
            <ScrollToTop />

            <Box p={['24px 0', '24px 0', '48px 0']} overflow='hidden'>
                <Container maxW='1312px'>
                    <Box
                        position='relative'
                        borderRadius='5px'
                        bg='brand.500'
                        color='#fff'
                        p={['4', '8', '16']}
                    >
                        <BlogPostCover>
                            <Image
                                src='https://picsum.photos/1920/1080'
                                alt='Dopo Covid ansia e depressione: effetti anche sulla mente dei pazienti'
                            />
                        </BlogPostCover>
                        <Heading
                            as='h1'
                            size='2xl'
                            position='relative'
                            mt={['4', '16', '64']}
                            maxW='32ch'
                        >
                            Dopo Covid ansia e depressione: effetti anche sulla mente dei pazienti
                        </Heading>
                    </Box>
                </Container>
            </Box>

            <Box p={['24px 0', '24px 0', '48px 0']}>
                <Container maxW='1312px'>
                    <Grid templateColumns={[null, null, null, '1fr 4fr 1fr']} gap={[4, 4, 4, 8]}>
                        <Box>
                            <ThumbDate isBig>
                                <big>26</big>
                                <small>Aug '20</small>
                            </ThumbDate>
                            <VStack spacing='12px' align='start' mt='4' fontWeight='500'>
                                <Link as={RouterLink} to='/blog' color='brand.700'>
                                    Coronavirus
                                </Link>
                                <Link as={RouterLink} to='/blog' color='brand.700'>
                                    2020
                                </Link>
                                <Link as={RouterLink} to='/blog' color='brand.700'>
                                    MALATTIE RARE 2020
                                </Link>
                            </VStack>
                        </Box>
                        <Box>
                            <Heading as='h2' size='xl' mb={[4, 8, 8]} color='brand.700'>
                                Uno studio dell’Ospedale San Raffaele mostra per la prima volta le
                                ripercussioni a medio termine che la malattia può lasciare dal punto
                                di vista psichiatrico
                            </Heading>
                            <Text fontSize='lg'>
                                Disturbo post traumatico da stress, ansia, insonnia e depressione:
                                dopo aver superato la malattia da coronavirus, i pazienti si trovano
                                spesso a fare i conti con disagi psicologici di varia natura e
                                gravità. Lo dimostra uno studio dell’Ospedale San Raffaele di
                                Milano, pubblicato sulla rivista Brain, Behavior and Immunity.
                                Coordinato dal professor Francesco Benedetti, psichiatra e group
                                leader dell’Unità di ricerca in Psichiatria e psicobiologia clinica,
                                il lavoro ha riguardato 402 pazienti nell’ambito dell’ambulatorio di
                                follow-up post Covid che il San Raffaele ha attivato a maggio. Si
                                tratta di un percorso di controlli di circa 6 mesi per i malati
                                guariti, che prevede visite con medici internisti, infettivologi,
                                neurologi, psichiatri, nefrologi e cardiologi…
                                <a href='/'>continua su Il Corriere</a>
                            </Text>
                        </Box>
                        <Box>
                            <Text as='h3' size='lg' color='brand.700' mb='4'>
                                Scegli il tuo Network e Condividi!
                            </Text>
                            <VStack spacing='12px' align='start'>
                                <TwitterShareButton url='/'>
                                    <TwitterIcon size={42} round={true} />
                                </TwitterShareButton>
                                <FacebookShareButton url='/'>
                                    <FacebookIcon size={42} round={true} />
                                </FacebookShareButton>
                                <LinkedinShareButton url='/'>
                                    <LinkedinIcon size={42} round={true} />
                                </LinkedinShareButton>
                                <WhatsappShareButton url='/'>
                                    <WhatsappIcon size={42} round={true} />
                                </WhatsappShareButton>

                                <EmailShareButton url='/'>
                                    <EmailIcon size={42} round={true} />
                                </EmailShareButton>
                            </VStack>
                        </Box>
                    </Grid>
                </Container>
            </Box>

            <Box p={['24px 0', '24px 0', '48px 0']} overflow='hidden'>
                <Container maxW='1312px'>
                    <Heading as='h2' size='lg' mb={[4, 8, 8]} color='brand.700'>
                        Post correlati
                    </Heading>
                    <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={10} mb={[4, 8, 8]}>
                        <BlogCard />
                        <BlogCard />
                        <BlogCard />
                    </SimpleGrid>
                </Container>
            </Box>
        </>
    );
};

export default BlogSingle;
