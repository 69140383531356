import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Spinner, Box } from '@chakra-ui/core';

import { useGetHeaderLinksQuery } from '../../../query/getHeaderLink';

const DinamicLink = ({ edition }) => {
    const linkData = useGetHeaderLinksQuery();
    if (linkData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.700' />
            </Box>
        );
    if (linkData.error) return <p>ERROR</p>;
    if (!linkData.data) return <p>Not found</p>;
    const dinamicLink = linkData.data.getHeaderLinks;
    // const getYear = () => {
    //     return new Date().getFullYear();
    // };
    //let edition = getYear();
    //let edition = '2020';
    return (
        <>
            {dinamicLink.length > 0 && (
                <>
                    {dinamicLink.map(link => (
                        <li key={link.id}>
                            {link.url === 'programma' ? (
                                // ||
                                // 'main-studio'
                                // ||
                                // 'hackathon' ||
                                // 'partner' ||
                                // 'comitato-scientifico'
                                <NavLink
                                    exact
                                    activeClassName='isActive'
                                    to={{ pathname: `/${link.url}/${edition}` }}
                                >
                                    {link.name}
                                </NavLink>
                            ) : link.url === 'main-studio' ? (
                                <NavLink
                                    exact
                                    activeClassName='isActive'
                                    to={{ pathname: `/${link.url}/${edition}` }}
                                >
                                    {link.name}
                                </NavLink>
                            ) : link.url === 'hackathon' ? (
                                <NavLink
                                    exact
                                    activeClassName='isActive'
                                    to={{ pathname: `/${link.url}/${edition}` }}
                                >
                                    {link.name}
                                </NavLink>
                            ) : link.url === 'partner' ? (
                                <NavLink
                                    exact
                                    activeClassName='isActive'
                                    to={{ pathname: `/${link.url}/${edition}` }}
                                >
                                    {link.name}
                                </NavLink>
                            ) : link.url === 'comitato-scientifico' ? (
                                <NavLink
                                    exact
                                    activeClassName='isActive'
                                    to={{ pathname: `/${link.url}/${edition}` }}
                                >
                                    {link.name}
                                </NavLink>
                            ) : link.url === 'past-editions' ? (
                                <NavLink
                                    exact
                                    activeClassName='isActive'
                                    to={{ pathname: `/${link.url}` }}
                                >
                                    {link.name}
                                </NavLink>
                            ) : (
                                <NavLink
                                    exact
                                    activeClassName='isActive'
                                    to={{ pathname: `/page/${link.url}` }}
                                >
                                    {link.name}
                                </NavLink>
                            )}
                        </li>
                    ))}
                </>
            )}
        </>
    );
};

export default DinamicLink;
