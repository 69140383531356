import React from 'react';
import { Box, Spinner } from '@chakra-ui/core';
import { useGetMyAgendaEventsGQLQuery } from '../query/getMyAgenda';
import Card from './myAgenda';

const AgendaList = () => {
    const myAgendaData = useGetMyAgendaEventsGQLQuery({ page: 1, limit: 20 });

    if (myAgendaData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.700' />
            </Box>
        );
    if (myAgendaData.error) return <p>ERROR</p>;
    if (!myAgendaData.data) return <p>Not found</p>;

    const agendas = myAgendaData.data.getMyAgendaEvents.eventDays;

    return (
        <>
            {agendas && (
                <>
                    {agendas.map(({ events, date }, index) => {
                        return (
                            <div key={index}>
                                <>
                                    {events.map(event => {
                                        return (
                                            <>
                                                {event.status === 1 ? (
                                                    <Card event={event} willbeLive key={event.id} />
                                                ) : (
                                                    <Card event={event} key={event.id} />
                                                )}
                                                <br />
                                            </>
                                        );
                                    })}
                                </>
                            </div>
                        );
                    })}
                </>
            )}
        </>
    );
};

export default AgendaList;
