import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Heading, Link, Grid, Box, Spinner } from '@chakra-ui/core';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useHistory, useParams } from 'react-router-dom';

import ScrollToTop from '../../common/scrollToTop';

import SliderEvents from './components/sliderEvents';
import MainSection from './mainSection';
import RightColumn from './rightColumn';
import { useSingleEventQueryGQLQuery } from '../queries/singleEvent';

const EventSingle = () => {
    let { id } = useParams();

    const eventData = useSingleEventQueryGQLQuery({ id });

    if (eventData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.700' />
            </Box>
        );

    if (eventData.error) return <p>ERROR</p>;
    if (!eventData.data) return <p>Not found</p>;
    const event = eventData.data.getSingleEvent;

    return (
        <>
            {/* <Helmet title={data.site.siteMetadata.title}>
			<meta name="description" content={data.site.siteMetadata.description} />
			<meta name="image" content={logo}/>
			<meta itemprop="name" content="Max McKinney"/>
			<meta itemprop="description" content={data.site.siteMetadata.description}/>
			<meta itemprop="image" content={logo}/>

			<meta name="twitter:card" content="summary"/>
			<meta name="twitter:title" content="Max McKinney"/>
			<meta name="twitter:description" content={data.site.siteMetadata.description}/>
			<meta name="twitter:site" content="@timmaxmckinney"/>
			<meta name="twitter:image" content={logo}/>

			<meta name="og:title" content="Max McKinney"/>
			<meta name="og:description" content={data.site.siteMetadata.description}/>
			<meta name="og:image" content={logo}/>
			<meta name="og:url" content="https://maxmckinney.com"/>
			<meta name="og:site_name" content="Max McKinney"/>
			<meta name="og:locale" content="en_US"/>
			<meta name="og:type" content="website"/>
		</Helmet> */}
            <ScrollToTop />
            <Container maxW='1312px' mb={[4, 4, 8, 12]}>
                <Box py={[4, 4, 4, 8]}>
                    <Link
                        as={RouterLink}
                        to={`/programma/${event.edition}`}
                        color='brand.700'
                        fontSize='14px'
                        fontWeight='500'
                        textTransform='uppercase'
                    >
                        <ArrowBackIcon boxSize={6} color='brand.700' /> Torna a programma
                    </Link>
                </Box>

                <Grid
                    templateColumns={{
                        base: '1fr',
                        sm: '1fr',
                        md: '2fr 1fr',
                        lg: '2.5fr 1fr',
                    }}
                    gap={{
                        base: 4,
                        sm: 4,
                        md: 6,
                        lg: 10,
                    }}
                >
                    {event && (
                        <>
                            <MainSection event={event} />
                            <RightColumn event={event} />
                        </>
                    )}
                </Grid>
            </Container>
            {event.eventVideos.length > 0 && (
                <Box p={['24px 0', '24px 0', '48px 0']} overflow='hidden' bg='white'>
                    <Container maxW='1312px'>
                        <Heading
                            as='h2'
                            size='xl'
                            mb={[4, 4, 6, 10]}
                            color='brand.700'
                            textTransform='uppercase'
                        >
                            Galleria Video
                        </Heading>
                        <SliderEvents videos={event.eventVideos} />
                    </Container>
                </Box>
            )}
        </>
    );
};

export default EventSingle;
