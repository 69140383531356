import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Container,
    Spacer,
    Heading,
    Text,
    Button,
    Image,
    Box,
    Stack,
    Link,
    Badge,
    Wrap,
    Tag,
    TagLabel,
    TagCloseButton,
    HStack,
    ButtonGroup,
    IconButton,
} from '@chakra-ui/core';

import { SearchIcon } from '@chakra-ui/icons';
import { IconSettings, IconDownload } from '../components/common/icons';
import VideoThumb from '../components/common/videoThumb';
import ScrollToTop from '../common/scrollToTop';
import imgLogo from '../images/logo-wide-dark.svg';
import NoResults from './components/noResults';

import {
    Timeline,
    TimelineDate,
    TimelineDateName,
    TimelineDateEvents,
    EventContainer,
} from './styles';

const Programs = () => {
    return (
        <>
            <ScrollToTop />
            <Container maxW='1312px' my={[4, 4, 8, 12]} centerContent>
                <Image src={imgLogo} alt='Global' />
            </Container>
            <Container maxW='1312px' mb={[8, 12, 24]}>
                <Stack direction={['column', 'column', 'row']} alignItems='end' mb={[4, 4, 8]}>
                    <HStack spacing='12px' align='center'>
                        <ButtonGroup as={RouterLink} to='/xxxxx' size='md' variant='grey'>
                            <IconButton aria-label='Download' icon={<IconDownload />} />
                        </ButtonGroup>
                        <Link as={RouterLink} to='/xxxxx' lineHeight='1.3'>
                            <Text as='div' fontSize='lg'>
                                Programma2020.pdf
                            </Text>
                            <Text as='div' color='brand.700' fontSize='sm'>
                                5.62 Mb
                            </Text>
                        </Link>
                    </HStack>
                    <Spacer />
                    <Button
                        variant='ghost'
                        rightIcon={<IconSettings boxSize={4} color='brand.700' />}
                    >
                        Filtra eventi
                    </Button>
                    <Button
                        variant='ghost'
                        rightIcon={<SearchIcon boxSize={4} color='brand.700' />}
                    >
                        Cerca tra gli eventi
                    </Button>
                </Stack>

                {/* Filters */}
                <Box my={[4, 4, 8, 12]} pb={[4, 4, 6]} borderBottom='2px solid #DEDFE0'>
                    <Wrap spacing='12px' align='center'>
                        <Heading as='h3' size='md' textTransform='uppercase'>
                            Filter:
                        </Heading>
                        <Tag size='lg' borderRadius='full' variant='solid' colorScheme='brand'>
                            <TagLabel>In diretta</TagLabel>
                            <TagCloseButton />
                        </Tag>
                        <Tag size='lg' borderRadius='full' variant='solid' colorScheme='brand'>
                            <TagLabel>1 Ottobre</TagLabel>
                            <TagCloseButton />
                        </Tag>
                        <Tag size='lg' borderRadius='full' variant='solid' colorScheme='brand'>
                            <TagLabel>Webinar</TagLabel>
                            <TagCloseButton />
                        </Tag>
                    </Wrap>
                </Box>

                {/* Mo results */}
                <NoResults />

                {/* Timeline */}
                <Timeline>
                    <TimelineDate>
                        <TimelineDateName>
                            <Heading as='h3' size='md' textTransform='uppercase' color='brand.700'>
                                Giovedi
                            </Heading>
                            <Text as='p' size='lg' mb='4'>
                                01/10
                            </Text>
                        </TimelineDateName>
                        <TimelineDateEvents>
                            <EventContainer>
                                <Box>
                                    <Text
                                        as='p'
                                        fontFamily='heading'
                                        fontSize='2xl'
                                        fontWeight='500'
                                        color='brand.700'
                                    >
                                        10:30 – 12:00
                                    </Text>
                                </Box>
                                <Box>
                                    <VideoThumb isLive isBig={false} />
                                </Box>
                                <Box>
                                    <Wrap spacing='12px' align='center' mb='2'>
                                        <Badge variant='brandDarkOutline' maxW='32ch' isTruncated>
                                            Planariafdgdfgdfgdfgdfgdfgdfgdfsdsdfsdfsdfsdfsdfsdfsdfsdfsdf
                                        </Badge>
                                        <Text
                                            as='span'
                                            fontSize='sm'
                                            fontWeight='500'
                                            textTransform='uppercase'
                                            color='brand.700'
                                        >
                                            #Opening
                                        </Text>
                                    </Wrap>
                                    <Heading as='h2' size='xl' mb='4'>
                                        <Link as={RouterLink} to='/single-event' color='brand.700'>
                                            La sanità digitale in italia, un processo partecipativo
                                            e di condivisione
                                        </Link>
                                    </Heading>
                                    <Text as='p' fontSize='lg' mb='4'>
                                        Responsabile Scientifico:{' '}
                                        <Text as='span' color='brand.700'>
                                            Sergio Pillon
                                        </Text>
                                    </Text>
                                    <Text as='p' fontSize='sm' mb='6' className='lines-3'>
                                        Il ruolo strategico dei network tra regioni, paesi e
                                        continenti. La recente pandemia ha evidenziato che per avere
                                        risposte sanitarie adeguate alle grandi sfide, note o
                                        impreviste, che ci troviamo ad affrontare occorrono nuove
                                        modalità di governance locale e nazionale per sanitarie
                                        adeguate alle grandi sfide, note o impreviste, che ci
                                        troviamo ad affrontare occorrono nuove modalità di
                                        governance locale e nazionale per
                                    </Text>
                                    <Wrap spacing='12px' align='center'>
                                        <Button size='xsm' variant='brand'>
                                            Aggiungi alla mia agenda
                                        </Button>
                                        <Button size='xsm' variant='brandDark'>
                                            Mi interessa
                                        </Button>
                                    </Wrap>
                                </Box>
                            </EventContainer>
                            <EventContainer>
                                <Box>
                                    <Text
                                        as='p'
                                        fontFamily='heading'
                                        fontSize='2xl'
                                        fontWeight='500'
                                        color='brand.700'
                                    >
                                        10:30 – 12:00
                                    </Text>
                                </Box>
                                <Box>
                                    <VideoThumb isLive={false} isBig={false} />
                                </Box>
                                <Box>
                                    <Wrap spacing='12px' align='center' mb='2'>
                                        <Badge variant='brandDarkOutline'>Webinar</Badge>
                                        <Text
                                            as='span'
                                            fontSize='sm'
                                            fontWeight='500'
                                            textTransform='uppercase'
                                            color='brand.700'
                                        >
                                            #Sanitadigitale
                                        </Text>
                                    </Wrap>
                                    <Heading as='h2' size='xl' mb='4'>
                                        <Link as={RouterLink} to='/single-event' color='brand.700'>
                                            Innovazione e cambiamento nel sistema della salute
                                        </Link>
                                    </Heading>
                                    <Text as='p' fontSize='lg' mb='4'>
                                        Responsabili Scientifici:{' '}
                                        <Text as='span' color='brand.700'>
                                            Milena Vainieri
                                        </Text>
                                        {' - '}
                                        <Text as='span' color='brand.700'>
                                            Sergio Pillon
                                        </Text>
                                    </Text>
                                    <Text as='p' fontSize='sm' mb='6' className='lines-3'>
                                        Il ruolo strategico dei network tra regioni, paesi e
                                        continenti. La recente pandemia ha evidenziato che per avere
                                        risposte sanitarie adeguate alle grandi sfide, note o
                                        impreviste, che ci troviamo ad affrontare occorrono nuove
                                        modalità di governance locale e nazionale per sanitarie
                                        adeguate alle grandi sfide, note o impreviste, che ci
                                        troviamo ad affrontare occorrono nuove modalità di
                                        governance locale e nazionale per
                                    </Text>
                                    <Wrap spacing='12px' align='center'>
                                        <Button size='xsm' variant='brand'>
                                            Aggiungi alla mia agenda
                                        </Button>
                                        <Button size='xsm' variant='brandDark'>
                                            Mi interessa
                                        </Button>
                                    </Wrap>
                                </Box>
                            </EventContainer>
                        </TimelineDateEvents>
                    </TimelineDate>
                    <TimelineDate>
                        <TimelineDateName>
                            <Heading as='h3' size='md' textTransform='uppercase' color='brand.700'>
                                Venerdi
                            </Heading>
                            <Text as='p' size='lg' mb='4'>
                                02/10
                            </Text>
                        </TimelineDateName>
                        <TimelineDateEvents>
                            <EventContainer>
                                <Box>
                                    <Text
                                        as='p'
                                        fontFamily='heading'
                                        fontSize='2xl'
                                        fontWeight='500'
                                        color='brand.700'
                                    >
                                        10:30 – 12:00
                                    </Text>
                                </Box>
                                <Box>
                                    <VideoThumb isPhoto imgUrl='https://picsum.photos/1024/768' />
                                </Box>
                                <Box>
                                    <Wrap spacing='12px' align='center' mb='2'>
                                        <Badge variant='brandDarkOutline'>Webinar</Badge>
                                        <Text
                                            as='span'
                                            fontSize='sm'
                                            fontWeight='500'
                                            textTransform='uppercase'
                                            color='brand.700'
                                        >
                                            #Sanitadigitale
                                        </Text>
                                    </Wrap>
                                    <Heading as='h2' size='xl' mb='4'>
                                        <Link as={RouterLink} to='/single-event' color='brand.700'>
                                            Innovazione e cambiamento nel sistema della salute
                                        </Link>
                                    </Heading>
                                    <Text as='p' fontSize='lg' mb='4'>
                                        Responsabili Scientifici:{' '}
                                        <Text as='span' color='brand.700'>
                                            Milena Vainieri
                                        </Text>
                                        {' - '}
                                        <Text as='span' color='brand.700'>
                                            Sergio Pillon
                                        </Text>
                                    </Text>
                                    <Text as='p' fontSize='sm' mb='6' className='lines-3'>
                                        Il ruolo strategico dei network tra regioni, paesi e
                                        continenti. La recente pandemia ha evidenziato che per avere
                                        risposte sanitarie adeguate alle grandi sfide, note o
                                        impreviste, che ci troviamo ad affrontare occorrono nuove
                                        modalità di governance locale e nazionale per sanitarie
                                        adeguate alle grandi sfide, note o impreviste, che ci
                                        troviamo ad affrontare occorrono nuove modalità di
                                        governance locale e nazionale per
                                    </Text>
                                    <Wrap spacing='12px' align='center'>
                                        <Text as='p' color='grey.300' fontSize='sm'>
                                            Aggiunto alla{' '}
                                            <Link as={RouterLink} to='/' color='brand.700'>
                                                Mia Agenda
                                            </Link>
                                        </Text>
                                    </Wrap>
                                </Box>
                            </EventContainer>
                            <EventContainer>
                                <Box>
                                    <Text
                                        as='p'
                                        fontFamily='heading'
                                        fontSize='2xl'
                                        fontWeight='500'
                                        color='brand.700'
                                    >
                                        10:30 – 12:00
                                    </Text>
                                </Box>
                                <Box>
                                    <VideoThumb
                                        isBig={false}
                                        isLive={false}
                                        imgUrl='https://picsum.photos/640/640'
                                    />
                                </Box>
                                <Box>
                                    <Wrap spacing='12px' align='center' mb='2'>
                                        <Badge variant='brandDarkOutline'>Webinar</Badge>
                                        <Text
                                            as='span'
                                            fontSize='sm'
                                            fontWeight='500'
                                            textTransform='uppercase'
                                            color='brand.700'
                                        >
                                            #Sanitadigitale
                                        </Text>
                                    </Wrap>
                                    <Heading as='h2' size='xl' mb='4'>
                                        <Link as={RouterLink} to='/single-event' color='brand.700'>
                                            Innovazione e cambiamento nel sistema della salute
                                        </Link>
                                    </Heading>
                                    <Text as='p' fontSize='lg' mb='4'>
                                        Responsabili Scientifici:{' '}
                                        <Text as='span' color='brand.700'>
                                            Milena Vainieri
                                        </Text>
                                        {' - '}
                                        <Text as='span' color='brand.700'>
                                            Sergio Pillon
                                        </Text>
                                    </Text>
                                    <Text as='p' fontSize='sm' mb='6' className='lines-3'>
                                        Il ruolo strategico dei network tra regioni, paesi e
                                        continenti. La recente pandemia ha evidenziato che per avere
                                        risposte sanitarie adeguate alle grandi sfide, note o
                                        impreviste, che ci troviamo ad affrontare occorrono nuove
                                        modalità di governance locale e nazionale per sanitarie
                                        adeguate alle grandi sfide, note o impreviste, che ci
                                        troviamo ad affrontare occorrono nuove modalità di
                                        governance locale e nazionale per
                                    </Text>
                                    <Wrap spacing='12px' align='center'>
                                        <Text as='p' color='grey.300' fontSize='sm'>
                                            Aggiunto alla{' '}
                                            <Link as={RouterLink} to='/' color='brand.700'>
                                                Mi Interessa
                                            </Link>
                                        </Text>
                                    </Wrap>
                                </Box>
                            </EventContainer>
                        </TimelineDateEvents>
                    </TimelineDate>
                </Timeline>
            </Container>
        </>
    );
};

export default Programs;
