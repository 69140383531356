import React, { useContext } from 'react';
import Moment from 'react-moment';
import { useEventsListQueryGQLQuery } from './queries/eventsList';
import { EventContext } from './context/EventContextProvider';
import Events from './events';
import { useParams } from 'react-router-dom';

import { Container, Heading, Text, Box, Spinner } from '@chakra-ui/core';

import { Timeline, TimelineDate, TimelineDateName, TimelineDateEvents } from '../programs/styles';
import 'moment/locale/it';
import { PrepareContext } from './context/PrepareContextProvider';
import NoResults from './noResults';

const EventList = () => {
    const { queryInput } = useContext(PrepareContext);
    const eventsData = useEventsListQueryGQLQuery({
        input: queryInput,
    });

    if (eventsData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.700' />
            </Box>
        );
    if (eventsData.error) return <p>ERROR</p>;
    if (!eventsData.data) return <p>Not found</p>;
    const eventsList = eventsData.data.getEvents.eventDays;
    Moment.globalWeekdays = true;
    // console.log(eventsList);
    return (
        <>
            <Container maxW='1312px' mb={[8, 12, 24]}>
                <Timeline>
                    {eventsList ? (
                        <>
                            {eventsList.map(({ events, date }, index) => {
                                return (
                                    <TimelineDate key={index}>
                                        <TimelineDateName key={date}>
                                            <Heading
                                                as='h3'
                                                size='md'
                                                textTransform='uppercase'
                                                color='brand.700'
                                            >
                                                <Moment format='dddd' local locale='it'>
                                                    {date}
                                                </Moment>
                                            </Heading>
                                            <Text as='p' size='lg' mb='4'>
                                                <Moment format='D/MM'>{date}</Moment>
                                            </Text>
                                        </TimelineDateName>
                                        <TimelineDateEvents>
                                            {events.map(event => {
                                                return <Events event={event} key={event.id} />;
                                            })}
                                        </TimelineDateEvents>
                                    </TimelineDate>
                                );
                            })}
                        </>
                    ) : (
                        <NoResults />
                    )}
                </Timeline>
            </Container>
        </>
    );
};

EventList.propTypes = {};

export default EventList;
