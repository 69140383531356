import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ScrollToTop from '../common/scrollToTop';
import { Container, Heading, Text, Box, Button, Link } from '@chakra-ui/core';

const Error404 = () => {
    return (
        <>
            <ScrollToTop />
            <Helmet>
                <title>404</title>
            </Helmet>
            <Box bg='brand.500' color='white' py={[4, 4, 8, 16]} minH='100vh'>
                <Container maxW='480px' textAlign='center'>
                    <Heading
                        as='h1'
                        fontSize={{ sm: '48px', md: '64px', lg: '200px' }}
                        fontWeight='500'
                        textAlign='center'
                    >
                        404
                    </Heading>
                    <Heading
                        as='h2'
                        color='brand.700'
                        fontSize={{ sm: '24px', md: '32px', lg: '48px' }}
                        textAlign='center'
                        textTransform='uppercase'
                    >
                        Pagina non trovata
                    </Heading>
                    <Text as='p' textAlign='center' my={[4, 4, 8, 12]}>
                        Il Link è interrotto o la pagina è stata spostata. Controlla l'URL o torna
                        alla{' '}
                        <Link as={RouterLink} to='/'>
                            <b>Homepage</b>
                        </Link>
                        .
                    </Text>
                    <Button
                        as={RouterLink}
                        to='/'
                        size='lg'
                        variant='white'
                        color='brand.700'
                        border='2px solid #8900a3'
                        isFullWidth
                    >
                        Torna alla Homepage
                    </Button>
                </Container>
            </Box>
        </>
    );
};

export default Error404;
