import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Heading, Text, Link, Image, Stack, Badge, Button } from '@chakra-ui/core';

import { PlayIcon } from '../../components/common/icons';
import { DeleteIcon } from '@chakra-ui/icons';

import Moment from 'react-moment';
import 'moment/locale/it';

import {
    CardContainer,
    CardThumb,
    CardThumbData,
    CardThumbContent,
    CardThumbPlay,
} from '../../components/card/styles';
import RemoveEventToAgendaButton from '../form/removeAgenda';

class Card extends Component {
    render() {
        const {
            isLive,
            willbeLive,
            videoDate,
            videoTime,
            title,
            subtitle,
            text,
            videoUrl,
            videoThumb,
            author,
            authorUrl,
            event,
        } = this.props;
        return (
            <CardContainer>
                <CardThumb willbeLive>
                    <Image loading='lazy' src={event.coverPhotoUrl} alt={title} />
                    <CardThumbData>
                        <Stack direction='row' align='center' spacing={3}>
                            {/* {isLive ? <>Live</> : willbeLive ? <>Will be live</> : <>was Live</>} */}
                            {!event.status === 2 ? (
                                <>
                                    <Badge variant='brand'>Live now</Badge>
                                    {event.startAtTimestamp ? (
                                        <Text as='span' fontSize='md' fontWeight='500'>
                                            <Moment unix format='D/MM'>
                                                {event.startAtTimestamp}
                                            </Moment>
                                        </Text>
                                    ) : null}
                                    {event.startAtTimestamp ? (
                                        <Text as='span' fontSize='sm' color='brand.100'>
                                            <Moment unix format='hh:mm A'>
                                                {event.startAtTimestamp}
                                            </Moment>
                                        </Text>
                                    ) : null}
                                </>
                            ) : event.status === 1 ? (
                                <>
                                    <Text as='span' fontSize='sm'>
                                        In diretta il{' '}
                                        <Moment unix format='D/MM'>
                                            {event.startAtTimestamp}
                                        </Moment>{' '}
                                        <Text as='span' color='brand.100'>
                                            ore{' '}
                                            <Moment unix format='hh:mm A'>
                                                {event.startAtTimestamp}
                                            </Moment>
                                        </Text>
                                    </Text>
                                </>
                            ) : (
                                event.status === 3 && (
                                    <>
                                        <Badge variant='brandOutline'>Replay</Badge>
                                        {videoDate ? (
                                            <Text as='span' fontSize='md'>
                                                Rivedi la diretta del{' '}
                                                <Moment unix format='D/MM'>
                                                    {event.startAtTimestamp}
                                                </Moment>
                                            </Text>
                                        ) : null}
                                    </>
                                )
                            )}
                        </Stack>
                    </CardThumbData>
                    {!event.status === 1 ? (
                        <CardThumbPlay as={RouterLink} to={videoUrl}>
                            <PlayIcon />
                        </CardThumbPlay>
                    ) : (
                        <CardThumbContent>
                            <Heading as='h3' size='md' mb='2'>
                                <Link
                                    as={RouterLink}
                                    to={`/single-programma/${event.id}`}
                                    color='white'
                                    className='lines-2-xxx'
                                >
                                    {event.name}
                                </Link>
                            </Heading>
                            <Text fontSize='sm' mb='2'>
                                {subtitle}{' '}
                                {event.eventSpeakers && (
                                    <>
                                        {event.eventSpeakers.map(speaker => (
                                            <Text
                                                key={speaker.id}
                                                as='span'
                                                color='brand.700'
                                                ml='2'
                                            >
                                                {speaker.name}
                                            </Text>
                                        ))}
                                    </>
                                )}
                            </Text>
                            <Text
                                fontSize='sm'
                                className='lines-3'
                                dangerouslySetInnerHTML={{ __html: event.excerpt }}
                            />
                        </CardThumbContent>
                    )}
                </CardThumb>
                {!event.status === 1 ? (
                    <>
                        <Heading as='h3' size='lg' mt='4' mb='2'>
                            <Link
                                as={RouterLink}
                                to={`/single-programma/${event.id}`}
                                color='brand.700'
                                className='lines-2-xxx'
                            >
                                {event.name}
                            </Link>
                        </Heading>
                        <Text fontSize='lg' mb='2'>
                            {subtitle}{' '}
                            {event.eventSpeakers && (
                                <>
                                    {event.eventSpeakers.map(speaker => (
                                        <Text key={speaker.id} as='span' color='brand.700' ml='2'>
                                            {speaker.name}
                                        </Text>
                                    ))}
                                </>
                            )}
                        </Text>
                        <Text
                            fontSize='sm'
                            className='lines-3'
                            dangerouslySetInnerHTML={{ __html: event.excerpt }}
                        />
                    </>
                ) : null}

                <RemoveEventToAgendaButton id={event.id} />
            </CardContainer>
        );
    }
}

Card.propTypes = {
    videoUrl: PropTypes.string.isRequired,
    videoThumb: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    authorUrl: PropTypes.string.isRequired,
    isLive: PropTypes.bool,
    willbeLive: PropTypes.bool,
    videoDate: PropTypes.string,
    videoTime: PropTypes.string,
    handleVideoSchedule: function (props) {
        if (!props['isLive']) {
            if (!props['videoDate'] || !props['videoTime']) {
                return new Error(
                    'Please provide a date and time of video! (videoDate and videoTime) ',
                );
            }
        } else {
            if (!props['videoTime']) {
                return new Error('Please provide a time of live video! (videoTime) ');
            }
        }
    },
};

Card.defaultProps = {
    videoUrl: './',
    videoThumb: 'https://picsum.photos/640/640',
    title: 'Lorem ipsum',
    subtitle: 'a cura di',
    text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut diam quam nulla porttitor massa id. Urna nunc id cursus metus aliquam eleifend mi in nulla. Mauris commodo quis imperdiet massa. Vitae suscipit tellus mauris a diam maecenas.',
    author: 'Sandro Pertini',
    authorUrl: './',
};

export default Card;
