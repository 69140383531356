import React from 'react';
//import { Link as RouterLink } from 'react-router-dom';
import {
    Heading,
    Text,
    SimpleGrid,
    Box,
    Image,
    Flex,
    Spacer,
    Badge,
    Button,
    Wrap,
    Link,
    HStack,
    ButtonGroup,
    IconButton,
    Avatar,
} from '@chakra-ui/core';
import { TwitterIcon, FacebookIcon, LinkedinIcon, WhatsappIcon, EmailIcon } from 'react-share';

import { IconDownload, IconYoutube, IconInstagram } from '../components/common/icons';
import { LogoHolder } from '../components/common/styles';
import { PartnerContainer } from './styles';

const Partner = ({ partner }) => {
    return (
        <PartnerContainer>
            <Box>
                <LogoHolder>
                    <Image loading='lazy' src={partner.logoUrl} alt={partner.name} />
                </LogoHolder>
            </Box>
            <Box>
                <Flex align='center' mb='6'>
                    <Heading as='h1' color='brand.700' size='xl'>
                        {partner.name}
                    </Heading>
                    <Spacer />
                    <Box ml='4'>
                        {partner.partnerTags && (
                            <>
                                {partner.partnerTags.map(tag => (
                                    <>
                                        {tag.id === '1' && (
                                            <Badge ml='2' variant='gold'>
                                                {tag.name}
                                            </Badge>
                                        )}
                                        {tag.id === '2' && (
                                            <Badge ml='2' variant='silver'>
                                                {tag.name}
                                            </Badge>
                                        )}
                                        {tag.id === '3' && (
                                            <Badge ml='2' variant='brandDarkOutline'>
                                                Si ringrazia inoltre
                                            </Badge>
                                        )}
                                        {tag.id === '4' && (
                                            <Badge ml='2' variant='brandDarkOutline'>
                                                {tag.name}
                                            </Badge>
                                        )}
                                        {tag.id === '5' && (
                                            <Badge ml='2' variant='brandDarkOutline'>
                                                {tag.name}
                                            </Badge>
                                        )}
                                        {tag.id === '6' && (
                                            <Badge ml='2' variant='brandDarkOutline'>
                                                {tag.name}
                                            </Badge>
                                        )}
                                    </>
                                    // <Badge key={tag.id} ml='2' variant='brandDarkOutline'>
                                    //     {tag.name}
                                    // </Badge>
                                ))}
                            </>
                        )}
                    </Box>
                </Flex>
                <Box
                    mb='6'
                    className='mainContentSmall'
                    dangerouslySetInnerHTML={{ __html: partner.description }}
                />

                <Flex align='center' mb='6'>
                    <Wrap spacing='12px'>
                        {partner.website1 && (
                            <Button
                                as='a'
                                href={partner.website1}
                                size='lg'
                                colorScheme='brand'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                Vai al sito
                            </Button>
                        )}
                        {partner.website2 && (
                            <Button
                                as='a'
                                href={partner.website2}
                                size='lg'
                                colorScheme='brand'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                Vai al sito
                            </Button>
                        )}
                    </Wrap>
                    <Spacer />
                    <Wrap spacing='12px'>
                        {partner.facebookUrl && (
                            <Link href={partner.facebookUrl} isExternal>
                                <FacebookIcon size={42} round={true} />
                            </Link>
                        )}
                        {partner.linkedinUrl && (
                            <Link href={partner.linkedinUrl} isExternal>
                                <LinkedinIcon size={42} round={true} />
                            </Link>
                        )}
                        {partner.twitterUrl && (
                            <Link href={partner.twitterUrl} isExternal>
                                <TwitterIcon size={42} round={true} />
                            </Link>
                        )}
                        {partner.youtubeUrl && (
                            <Link href={partner.youtubeUrl} isExternal>
                                <Avatar
                                    w='2.6rem'
                                    h='2.6rem'
                                    bg='red.500'
                                    icon={<IconYoutube fontSize='24px' />}
                                />
                            </Link>
                        )}
                        {partner.instagramUrl && (
                            <Link href={partner.instagramUrl} isExternal>
                                <Avatar
                                    w='2.6rem'
                                    h='2.6rem'
                                    bg='#c02176'
                                    icon={<IconInstagram fontSize='24px' />}
                                />
                            </Link>
                        )}

                        {/* <Link href='https://google.com' isExternal>
                            <WhatsappIcon size={42} round={true} />
                        </Link>
                        <Link href='https://google.com' isExternal>
                            <EmailIcon size={42} round={true} />
                        </Link> */}
                    </Wrap>
                </Flex>
                {partner.partnerFiles.length > 0 && (
                    <>
                        <Heading as='h3' size='md' textTransform='uppercase' mb='6'>
                            Scarica file
                        </Heading>
                        <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={4}>
                            {partner.partnerFiles.map(file => (
                                <Box>
                                    <HStack spacing='12px' align='center'>
                                        <Link href={file.downloadUrl} isExternal lineHeight='1.3'>
                                            <IconButton
                                                bg='grey.100'
                                                _hover={{ bg: 'brand.500', color: 'white' }}
                                                color='brand.700'
                                                aria-label='Download'
                                                icon={<IconDownload />}
                                            />
                                        </Link>
                                        <Link href={file.downloadUrl} isExternal lineHeight='1.3'>
                                            <Text
                                                as='div'
                                                fontSize='lg'
                                                color='brand.700'
                                                isTruncated
                                                maxW='22ch'
                                            >
                                                {file.name}
                                            </Text>
                                            <Text as='div' color='brand.700' fontSize='sm'>
                                                {file.fileSize}
                                            </Text>
                                        </Link>
                                    </HStack>
                                    <Box bg='grey.100' height='2px' width='100%' mt='4' />
                                </Box>
                            ))}
                        </SimpleGrid>
                    </>
                )}
            </Box>
        </PartnerContainer>
    );
};

export default Partner;
