import React from 'react';
import { useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import { Container, Heading, Text, Box, Spinner } from '@chakra-ui/core';

import ScrollToTop from '../common/scrollToTop';

import { usePartnersGQLQuery } from './query/getPartners';
import Partner from './partner';

const Partners = () => {
    let { edition } = useParams();
    const { loading, data, error } = usePartnersGQLQuery({ editionName: edition });

    if (loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.700' />
            </Box>
        );
    if (error) return <p>ERROR</p>;
    if (!data) return <p>Not found</p>;

    const partners = data.getPartners;

    return (
        <>
            <ScrollToTop />
            <Helmet>
                <title>Partner</title>
            </Helmet>
            <Container maxW='1312px' my={[4, 4, 8, 16]}>
                <Heading as='h1' color='brand.700' size='2xl'>
                    Partner
                </Heading>
                <Text
                    mb={[4, 4, 8, 12]}
                    as='p'
                    fontFamily='heading'
                    color='brand.700'
                    fontSize='2xl'
                    fontWeight='500'
                >
                    Con il contributo incondizionato di
                </Text>
                {partners && (
                    <>
                        {partners.map(partner => (
                            <Partner partner={partner} key={partner.id} />
                        ))}
                    </>
                )}
            </Container>
        </>
    );
};

export default Partners;
