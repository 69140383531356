import React, { useContext } from 'react';
import { Stack, Box, Spinner, Image } from '@chakra-ui/core';
import { ExpandRight, SliderPrev, SliderNext } from '../../components/common/styles';

import { GoRightIcon, GoLeftIcon } from '../../components/common/icons';
import Card from '../../components/card/card';
import ImgLive from '../../images/slider-live-img.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { useEventsListQueryGQLQuery } from '../../events/queries/eventsList';

import 'swiper/swiper-bundle.css';
SwiperCore.use([Navigation, Pagination, Autoplay]);

const SliderLiveShows = () => {
    const eventsData = useEventsListQueryGQLQuery({
        input: {
            eventCategory: [],
            startAt: [],
            status: ['2'],
            eventLocation: [],
            searchTerm: '',
            limit: '10',
        },
    });

    if (eventsData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.700' />
            </Box>
        );
    if (eventsData.error) return <p>ERROR</p>;
    if (!eventsData.data) return <p>Not found</p>;
    const eventsList = eventsData.data.getEvents.eventDays;
    return (
        <>
            <Box>
                <Swiper
                    spaceBetween={16}
                    slidesPerView={1}
                    loop={false}
                    autoHeight={true}
                    allowTouchMove={true}
                    navigation={{
                        nextEl: '.next-liveshow',
                        prevEl: '.prev-liveshow',
                    }}
                    autoplay={{
                        delay: 7000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        375: {
                            slidesPerView: 1,
                            spaceBetween: 16,
                        },
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 32,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 32,
                        },
                        1366: {
                            slidesPerView: 4,
                            spaceBetween: 32,
                        },
                    }}
                >
                    {eventsList ? (
                        <>
                            {eventsList.map(({ events }, index) => {
                                return (
                                    <>
                                        {events.map(event => {
                                            return (
                                                <>
                                                    <SwiperSlide key={event.id}>
                                                        <Card isLive event={event} />
                                                    </SwiperSlide>
                                                </>
                                            );
                                        })}
                                    </>
                                );
                            })}
                        </>
                    ) : (
                        <SwiperSlide>
                            <Box textAlign='center' mt='6'>
                                <Image src={ImgLive} alt='live' m='auto' />
                            </Box>
                        </SwiperSlide>
                    )}
                </Swiper>
            </Box>
            <Stack
                mt='8'
                // mb={['0', '0', '-12']}
                w='100%'
                direction='row'
                justify='flex-end'
                spacing={6}
            >
                <SliderPrev className='prev-liveshow'>
                    <GoLeftIcon />
                </SliderPrev>
                <SliderNext className='next-liveshow'>
                    <GoRightIcon />
                </SliderNext>
            </Stack>
        </>
    );
};

export default SliderLiveShows;
