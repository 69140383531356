import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { useAuthToken } from '../config/auth';

const httpLink = new HttpLink({
    // uri: "http://fss.nisyncdev.com/graphql/",
    //uri: "http://localhost/graphql/"
    // uri: 'http://fss.nisyncdev.com/graphql/',
    // uri: 'http://web-balancer-710485551.eu-west-1.elb.amazonaws.com/graphql/',
    //uri: 'http://ec2-34-246-200-131.eu-west-1.compute.amazonaws.com/graphql/',
    //uri: 'http://application-lb-1820934181.eu-west-1.elb.amazonaws.com/',
    uri: 'https://global.klive.it/graphql/',
});

const authMiddleware = authToken =>
    new ApolloLink((operation, forward) => {
        //authToken = null;
        // add the authorization to the headers
        if (authToken) {
            operation.setContext({
                headers: {
                    authorization: authToken ? `Bearer ${authToken}` : null,
                },
            });
        }

        return forward(operation);
    });

const cache = new InMemoryCache({});

export const useAppApolloClient = () => {
    const [authToken] = useAuthToken();
    return new ApolloClient({
        link: authMiddleware(authToken).concat(httpLink),
        cache,
    });
};
