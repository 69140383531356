import React, { useRef } from 'react';
import { FormErrorMessage, FormLabel, FormControl, Input, Button, Text } from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import { useChangePasswordMutation } from '../mutations/changePassword';

const ChangePasswordForm = ({ loading }) => {
    const { handleSubmit, errors, register, reset } = useForm();
    const [changePasswordMutation, changePasswordResults] = useChangePasswordMutation(errors);
    const disableForm = changePasswordResults.loading || loading;
    const onSubmit = (values, errors) =>
        changePasswordMutation(
            values.password,
            values.newPassword,
            values.confirmPassword,
            errors.password,
            errors.newPassword,
            errors.confirmPassword,
        );
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Text fontSize='18' textTransform='uppercase' mb='4'>
                    <strong>Cambia la password</strong>
                </Text>
                <FormControl isInvalid={errors.password} mb={[4, 4, 8]}>
                    <FormLabel htmlFor='passwordAttuale'>Password attuale</FormLabel>
                    <Input
                        type='password'
                        name='passwordAttuale'
                        id='passwordAttuale'
                        placeholder='Password attuale'
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                            minLength: {
                                value: 6,
                                message: 'password deve contenere almeno 6 caratteri',
                            },
                        })}
                    />
                    <FormErrorMessage>
                        {errors.password?.message || errors.password}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.newPassword} mb={[4, 4, 8]}>
                    <FormLabel htmlFor='newPassword'>nuova password</FormLabel>
                    <Input
                        type='password'
                        name='newPassword'
                        id='newPassword'
                        placeholder='nuova password'
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                            minLength: {
                                value: 6,
                                message: 'password deve contenere almeno 6 caratteri',
                            },
                        })}
                    />
                    <FormErrorMessage>
                        {errors.newPassword?.message || errors.newPassword}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.confirmPassword} mb={[4, 4, 8]}>
                    <FormLabel htmlFor='confirmPassword'>conferma la nuova password</FormLabel>
                    <Input
                        type='password'
                        name='confirmPassword'
                        id='confirmPassword'
                        placeholder='nuova password'
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                            minLength: {
                                value: 6,
                                message: 'password deve contenere almeno 6 caratteri',
                            },
                        })}
                    />
                    <FormErrorMessage>
                        {errors.password?.message || errors.password}
                    </FormErrorMessage>
                </FormControl>

                <Button type='submit' size='md' variant='brand' disabled={disableForm}>
                    Salva
                </Button>
                <Button size='md' variant='grey' ml='3' onClick={reset}>
                    Cancella
                </Button>
            </form>
        </>
    );
};
export default ChangePasswordForm;
