import styled from 'styled-components';
import { createBreakpoint } from 'styled-components-breakpoint';
import { styledBreakpoints } from '../../../style/styledBreakpoints';
import { customTheme } from '../../../style/customChakraTheme.js';
const breakpoint = createBreakpoint(styledBreakpoints);

// background: ${customTheme.colors.brand[500]};
// background: ${props => (props.light && customTheme.colors.brand[100]) || (props.dark && customTheme.colors.brand[700]) || customTheme.colors.brand[500]};
// `url(./images/bg-login.png)`

export const FooterContainer = styled.div`
    background: ${customTheme.colors.brand[700]};
    color: #fff;
    padding-top: 32px;

    ${breakpoint('md')`
        padding-top: 80px;
    `}
`;
export const FooterGrid = styled.div`
    display: grid;
    grid-gap: 1rem;

    > * {
        min-width: 0;
    }

    ${breakpoint('md')`
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
    `}
    ${breakpoint('xl')`
        grid-template-columns: 1.4fr 1fr 1fr 1fr;
        grid-gap: 48px;
    `}
`;
export const FooterBottom = styled.div`
    display: grid;
    grid-gap: 1rem;
    margin-top: 32px;
    padding: 24px 0;
    border-top: 2px solid #fff;
    color: #fff;

    > * {
        min-width: 0;
        align-self: center;
    }

    .social-links a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        font-size: 20px;
        /* color: ${customTheme.colors.brand[700]}; */
        color: #fff;
        transition: all 0.2s ease-in-out;

        &:hover {
            color: ${customTheme.colors.brand[500]};
            background: #fff;
        }
    }

    ${breakpoint('md')`
        grid-template-columns: repeat(2, 1fr);
        `}
`;
export const FooterLinks = styled.ul`
    list-style: none;
    font-size: 14px;
    letter-spacing: 0.1em;
    font-weight: 500;
    text-transform: uppercase;

    li {
        list-style: none;
        margin: 0;
    }
    li + li {
        margin-top: 1rem;
    }

    a {
        position: relative;

        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: calc(100% + 4px);
            left: 0;
            width: 100%;
            height: 2px;
            border-radius: 50em;
            transition: transform 0.2s ease-in-out;
            background: ${customTheme.colors.brand[700]};
            transform-origin: 0 0;
            transform: scaleX(1);
        }

        &:hover {
            text-decoration: none;
            &::after {
                transform: scaleX(0.5);
            }
        }
    }
`;
