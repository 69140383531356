import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FormControl, InputGroup, Input, InputRightElement, Text, Link } from '@chakra-ui/core';
import { SearchIcon } from '@chakra-ui/icons';
import FaqAcordionList from './accordionList';
const Search = () => {
    const [searchTerm, setSearchTerm] = React.useState('');
    const handleChange = event => {
        setSearchTerm(event.target.value);
    };
    return (
        <div>
            <form>
                <FormControl>
                    <InputGroup size='lg'>
                        <Input
                            name='search-faq'
                            placeholder='Cerca tra le domande'
                            value={searchTerm}
                            onChange={handleChange}
                        />
                        <InputRightElement
                            children={<SearchIcon boxSize={16} color='brand.700' />}
                        />
                    </InputGroup>
                </FormControl>
            </form>
            <>
                <Text fontSize='sm' mt={[4, 4, 8]} mb='4'>
                    Non hai trovato la risposta che cercavi? Clicca{' '}
                    <Link as={RouterLink} to='/' color='brand.700'>
                        qui
                    </Link>{' '}
                    per inoltrare una richiesta.
                </Text>
            </>
            <FaqAcordionList searchTerm={searchTerm} />
        </div>
    );
};

export default Search;
