import React from 'react';
import { Heading, Box, Wrap, Avatar, Text } from '@chakra-ui/core';

const SpeakerList = ({ speakers }) => {
    return (
        <Box>
            <Heading as='h3' size='md' mb='4' textTransform='uppercase' color='white'>
                Responsabili Scientifici
            </Heading>
            <Wrap spacing='32px' align='center'>
                {speakers.map(speaker => (
                    <Wrap key={speaker.id} spacing='12px' align='center'>
                        <Avatar size='md' name={speaker.name} src={speaker.photoUrl} />
                        <Text color='brand.700'>{speaker.name}</Text>
                    </Wrap>
                ))}
            </Wrap>
        </Box>
    );
};

export default SpeakerList;
