import React from 'react';
import { useLogout } from './config/auth';

import { Button } from '@chakra-ui/core';

const Private = ({ user }) => {
    const logout = useLogout();

    return (
        <div style={{ margin: 'auto', padding: '100px' }}>
            <h4>Congratulations, you are authentified !</h4>
            {/* <p>{user.first_name}</p> */}
            <pre style={{ backgroundColor: '#eee' }}>{JSON.stringify(user, null, 2)}</pre>
            <div>
                <Button variant='brand' onClick={logout}>
                    Logout
                </Button>
            </div>
        </div>
    );
};

export default Private;
