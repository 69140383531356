import { gql, useQuery } from '@apollo/client';

const getPortalSettingsQueryGQL = gql`
    query {
        getPortalSettings {
            name
            edition {
                name
            }
            description
            eventLinksProtected
            logoUrl
            mobileLogoUrl
            liveNowTitle
            liveNowDescription
        }
    }
`;

export const useGetPortalSettingsQuery = () => {
    return useQuery(getPortalSettingsQueryGQL);
};
