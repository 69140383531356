import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Heading, Link, Text, AspectRatio } from '@chakra-ui/core';
import { SliderDots } from '../components/common/styles';

import VideoThumb from '../components/common/videoThumb';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Pagination]);

const SliderPresentationVideos = ({ videos }) => {
    return (
        <>
            <Swiper
                spaceBetween={16}
                slidesPerView={1}
                loop={false}
                autoHeight={true}
                pagination={{
                    el: '.c-pagination-presentation-videos',
                    type: 'bullets',
                    clickable: 'true',
                }}
                breakpoints={{
                    375: {
                        slidesPerView: 1,
                        spaceBetween: 16,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 32,
                    },
                }}
            >
                {videos.map(video => (
                    <SwiperSlide>
                        <AspectRatio
                            // ratio={16 / 9}
                            ratio={1.7777}
                            borderRadius='md'
                            style={{ overflow: 'hidden' }}
                        >
                            <>
                                <>
                                    <iframe
                                        title='xxxxxx'
                                        allowFullScreen
                                        src={`https://www.youtube.com/embed/${video.url}`}
                                        allow='autoplay'
                                    />
                                </>
                            </>
                        </AspectRatio>
                        <Heading as='h3' size='lg' my='2'>
                            <Link
                                as={RouterLink}
                                to='/xxxxx'
                                color='brand.700'
                                className='lines-2-xxx'
                            >
                                {video.title}
                            </Link>
                        </Heading>
                        <Text as='p' className='lines-3' fontSize='sm'>
                            {video.description}
                        </Text>
                    </SwiperSlide>
                ))}
            </Swiper>
            <SliderDots className='c-pagination-presentation-videos' />
        </>
    );
};

export default SliderPresentationVideos;
