import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Container, Heading, SimpleGrid, Box, Image, Spinner, Text } from '@chakra-ui/core';
import ScrollToTop from '../common/scrollToTop';
import { useSponsorsGQLQuery } from './query/patrociniQuery';

import { LogoLink } from '../components/common/styles';
const Patrocini = () => {
    let { edition } = useParams();
    const { data, loading, error } = useSponsorsGQLQuery({
        editionName: edition,
    });

    if (loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.700' />
            </Box>
        );
    if (error) return <p>ERROR</p>;
    if (!data) return <p>Not found</p>;

    const sponsors = data.getSponsors;
    return (
        <>
            <ScrollToTop />
            <Helmet>
                <title>Patrocini</title>
            </Helmet>
            <Container maxW='1312px' my={[4, 4, 8, 16]}>
                <Heading as='h1' color='brand.700' size='2xl'>
                    Patrocini
                </Heading>
                <Text
                    mb={[4, 4, 8, 12]}
                    as='p'
                    fontFamily='heading'
                    color='brand.700'
                    fontSize='2xl'
                    fontWeight='500'
                >
                    Con il patrocinio di:
                </Text>
                <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={10} my={[4, 4, 8]}>
                    {sponsors && (
                        <>
                            {sponsors.map(sponsor => (
                                <LogoLink
                                    href={sponsor.website1}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <Image
                                        loading='lazy'
                                        src={sponsor.logoUrl}
                                        alt={sponsor.name}
                                    />
                                </LogoLink>
                            ))}
                        </>
                    )}
                </SimpleGrid>
            </Container>
        </>
    );
};

export default Patrocini;
