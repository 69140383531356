import React from 'react';
import { useParams } from 'react-router-dom';

import {
    Container,
    Heading,
    Text,
    Box,
    Link,
    Badge,
    Wrap,
    Grid,
    AspectRatio,
    Spinner,
} from '@chakra-ui/core';

import EventContactForm from '../form/eventContact';
import { useSingleEventQueryGQLQuery } from '../queries/singleEvent';
import ScrollToTop from '../../common/scrollToTop';
import FilesList from '../singleEvent/components/filesList';
import SpeakerList from './component/speakerList';
import Moment from 'react-moment';
import 'moment/locale/it';

const ProgramPreviewSlider = () => {
    let { id } = useParams();
    //const eventData = useSingleEventQueryGQLQuery({ id });

    // if (eventData.loading)
    //     return (
    //         <Box textAlign='center'>
    //             <Spinner color='brand.700' />
    //         </Box>
    //     );
    // if (eventData.error) return <p>ERROR</p>;
    // if (!eventData.data) return <p>Not found</p>;
    // const event = eventData.data.getSingleEvent;
    // console.log(event.liveVideoUrl);
    // console.log(id);
    return (
        <Box bg='black'>
            <ScrollToTop />
            <Container maxW='1312px' py={[4, 4, 8, 12]}>
                <Grid
                    templateColumns={{
                        base: '1fr',
                        sm: '1fr',
                        md: '2fr 1fr',
                        lg: '2.5fr 1fr',
                    }}
                    gap={{
                        base: 4,
                        sm: 4,
                        md: 6,
                        lg: 10,
                    }}
                >
                    <Box>
                        <Box mb={[4, 4, 6, 10]}>
                            <AspectRatio
                                // ratio={16 / 9}
                                ratio={1.7777}
                                borderRadius='md'
                                style={{ overflow: 'hidden' }}
                            >
                                <iframe
                                    title='xxxxxx'
                                    allowFullScreen
                                    //src='https://www.youtube.com/embed/36YnV9STBqc?&amp;autoplay=1'
                                    src={`https://www.youtube.com/embed/${id}`}
                                    allow='autoplay'
                                />
                            </AspectRatio>
                        </Box>
                        {/* END - if it's live */}
                        {/* <Wrap spacing='12px' align='center' mb='2'>
                            <Badge variant='brandDarkOutline'>{event.eventCategory.name}</Badge>
                            {event.tag && (
                                <Text
                                    as='span'
                                    fontSize='sm'
                                    fontWeight='500'
                                    textTransform='uppercase'
                                >
                                    #{event.tag}
                                </Text>
                            )}
                        </Wrap> */}
                        {/* <Heading as='h1' size='2xl' color='brand.700' mb={[4, 4, 6]} color='white'>
                            {event.name}
                        </Heading> */}
                        {/* <Wrap spacing='12px' align='center' mb={[4, 4, 6, 10]} color='white'>
                            <Badge fontSize='1.4em' variant='brandDark' textTransform='uppercase'>
                                <Text as='span' color='grey.100'>
                                    <Moment unix format='dddd' local locale='it'>
                                        {event.startAtTimestamp}
                                    </Moment>
                                </Text>{' '}
                                <Moment unix format='D/MM'>
                                    {event.startAtTimestamp}
                                </Moment>
                            </Badge>
                            <Text as='p' fontFamily='heading' fontSize='2xl' fontWeight='500'>
                                <Moment unix format='hh:mm'>
                                    {event.startAtTimestamp}
                                </Moment>
                                -
                                <Moment unix format='hh:mm'>
                                    {event.endAtTimestamp}
                                </Moment>
                            </Text>
                        </Wrap> */}
                        {/* <Box className='mainContent' mb={[4, 4, 10]} color='white'>
                            <p dangerouslySetInnerHTML={{ __html: event.description }}></p>
                        </Box> */}
                    </Box>
                    {/* Right column */}
                    <Box></Box>
                </Grid>
            </Container>
        </Box>
    );
};

export default ProgramPreviewSlider;
