import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormErrorMessage, FormLabel, FormControl, Input, Button } from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import { usePasswordRestoreMutation } from '../mutations/resetPasswordMutation';

const RestorePasswordForm = ({ loading }) => {
    const { handleSubmit, register, errors } = useForm();
    let history = useHistory();
    let { resetToken } = useParams();
    const [restorePasswordMutation, restorePasswordResults] = usePasswordRestoreMutation(errors);
    const disableForm = restorePasswordResults.loading || loading;
    const onSubmit = (values, errors) =>
        restorePasswordMutation(
            values.password,
            values.confirmPassword,
            (values.token = resetToken),
            errors.password,
            errors.confirmPassword,
        );
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl isInvalid={errors.password} mb={[4, 4, 8]}>
                    <FormLabel htmlFor='password'>Nuova password</FormLabel>
                    <Input
                        type='password'
                        name='password'
                        id='password'
                        placeholder='password'
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                            minLength: {
                                value: 8,
                                message: 'password deve contenere almeno 8 caratteri',
                            },
                        })}
                    />
                    <FormErrorMessage>
                        {errors.password?.message || errors.password}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.confirmPassword} mb={[4, 4, 8]}>
                    <FormLabel htmlFor='confirmPassword'>Conferma nuova password</FormLabel>
                    <Input
                        type='password'
                        name='confirmPassword'
                        id='confirmPassword'
                        placeholder='conferma password'
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                            minLength: {
                                value: 8,
                                message: 'password deve contenere almeno 8 caratteri ',
                            },
                        })}
                    />
                    <FormErrorMessage>
                        {errors.confirmPassword?.message || errors.confirmPassword}
                    </FormErrorMessage>
                </FormControl>
                <Button type='submit' size='lg' variant='brand' isFullWidth disabled={disableForm}>
                    imposta la nuova password
                </Button>
            </form>
        </>
    );
};
export default RestorePasswordForm;
