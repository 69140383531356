import React from 'react';
import { Container, Heading, SimpleGrid, Text, Box, Spinner } from '@chakra-ui/core';
import { useParams } from 'react-router-dom';

import ScrollToTop from '../common/scrollToTop';
import { Helmet } from 'react-helmet-async';
import { useComitatoQueryGQL } from './query/getComitato';

const ComitatoScientifico = () => {
    let { edition } = useParams();
    const { loading, data, error } = useComitatoQueryGQL({ editionName: edition });

    if (loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.700' />
            </Box>
        );
    if (error) return <p>ERROR</p>;
    if (!data) return <p>Not found</p>;

    const comitatos = data.getComitatoScietifico;

    return (
        <>
            <ScrollToTop />
            <Helmet>
                <title>Comitato Scientifico</title>
            </Helmet>
            <Container maxW='1312px' my={[4, 4, 8, 12]}>
                <>
                    {comitatos ? (
                        <div>
                            {comitatos.map(({ speakers, category }, index) => {
                                return (
                                    <div key={index}>
                                        <Box>
                                            <Heading as='h1' color='brand.700' size='2xl'>
                                                {category}
                                            </Heading>
                                            <SimpleGrid
                                                columns={{ sm: 1, md: 2, lg: 3 }}
                                                spacing={10}
                                                my={[4, 4, 8, 16]}
                                            >
                                                {speakers.map(speaker => {
                                                    return (
                                                        <Box key={speaker.id}>
                                                            <Heading
                                                                as='h3'
                                                                color='brand.700'
                                                                size='lg'
                                                                mb='4'
                                                            >
                                                                {speaker.name}
                                                            </Heading>
                                                            <Text fontSize='sm'>
                                                                {speaker.description}
                                                            </Text>
                                                        </Box>
                                                    );
                                                })}
                                            </SimpleGrid>
                                            <Box bg='grey.100' height='2px' my={[4, 4, 8]} />
                                        </Box>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <p>no results</p>
                    )}

                    {/* <Heading as='h1' color='brand.700' size='2xl'>
                        Advisory Board 2020
                    </Heading>
                    <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={10} my={[4, 4, 8, 16]}>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Alessandro Agnetis
                            </Heading>

                            <Text fontSize='sm'>
                                Professore Ordinario di Ricerca Operativa presso il dipartimento di
                                Ingegneria dell’Informazione e Scienze Matematiche, Università di
                                Siena
                            </Text>
                        </Box>

                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Elio Borgonovi{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Docente Senior, Dipartimento di Scienze Sociale e Politiche,
                                Università Bocconi{' '}
                            </Text>
                        </Box>

                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Mario Braga
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore ARS – Agenzia regionale di Sanità della Toscana{' '}
                            </Text>
                        </Box>

                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Nerina Dirindin{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Associazione “Salute diritto fondamentale” e Università di Torino{' '}
                            </Text>
                        </Box>

                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Gian Franco Gensini{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Presidente Digital SIT – Società per la Salute Digitale e la
                                Telemedicina{' '}
                            </Text>
                        </Box>

                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Filomena Maggino{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Professoressa di Statistica Sociale, Università La Sapienza di Roma{' '}
                            </Text>
                        </Box>

                        <Box>
                            {' '}
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Nello Martini{' '}
                            </Heading>{' '}
                            <Text fontSize='sm'>Presidente Fondazione ReS – Ricerca e Salute</Text>{' '}
                        </Box>

                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Alessandro Solipaca{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore Scientifico Osservatorio Nazionale sulla Salute nelle
                                Regioni Italiane, Università Cattolica del Sacro Cuore di Roma{' '}
                            </Text>
                        </Box>
                    </SimpleGrid>
                    <Box bg='grey.100' height='2px' my={[4, 4, 8]} />{' '}
                    <Heading as='h1' color='brand.700' size='2xl'>
                        Comitato Scientifico 2020{' '}
                    </Heading>
                    <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={10} my={[4, 4, 8, 16]}>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Gianni Amunni{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore Generale ISPRO – Istituto per lo studio, la prevenzione e
                                la rete oncologica Toscana{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Alessandro Bacci{' '}
                            </Heading>
                            <Text fontSize='sm'>CEO Telos Management Consulting</Text>{' '}
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Andrea Belardinelli{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Responsabile di settore Sanità digitale e innovazione, Regione
                                Toscana{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Cecilia Berni{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Responsabile programmazione e monitoraggio della rete regionale
                                delle malattie rare e dei difetti congeniti e della rete dei punti
                                nascita e della pediatria specialistica, Regione Toscana{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Oscar Bertetto
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore Dipartimento Rete Oncologica Piemonte Valle d’Aosta – sede
                                AOU Città della Salute e Scienza di Torino{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Attilio Bianchi{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore Generale Istituto Nazionale Tumori IRCCS Fondazione “G.
                                Pascale” di Napoli{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Massimo Bisogno{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Gabinetto del Presidente della Giunta Regionale; Dirigente Ufficio V
                                Università, ricerca, innovazione, Istruzione, formazione, lavoro e
                                politiche giovanili. Politiche culturali e turismo. Amministrazione
                                digitale, informatizzazione, egovernment{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Antonio Bonaldi{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Medico, Esperto di Sanità pubblica. Presidente di Slow Medicine{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Riccardo Bond{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Avvocato di diritto amministrativo, specializzato in appalti e
                                contrattualistica pubblica, studio legale Avv. Vittorio Miniero{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Maria Luisa Brandi{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Presidente FIRMO – Direttore SOD Malattie del Metabolismo Minerale e
                                Osseo, AOU Careggi Firenze{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Massimo Brunetti{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Economista, Responsabile del Servizio Internal Audit e
                                Anticorruzione Azienda USL di Modena{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Nino Cartabellotta{' '}
                            </Heading>
                            <Text fontSize='sm'>Presidente Fondazione GIMBE</Text>{' '}
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Arturo Cavaliere{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Presidente SIFO – Società Italiana di Farmacia Ospedaliera e dei
                                Servizi Farmaceutici{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Cristina Cenci{' '}
                            </Heading>
                            <Text fontSize='sm'>Digital Narrative Medicine</Text>{' '}
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Carlo Centemeri{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Adjunct Professor of Pharmacology, University Polyclinic of Roma II,
                                Padua, Milan; Strategy & Development Senior Managing Partner the
                                Giovanni Lorenzini Medical Foundation{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Alfredo Cesario{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Responsabile della Segreteria Scientifica della Fondazione
                                Policlinico Universitario Agostino Gemelli IRCCS; Presidente
                                Associazione Italiana di Medicina e Sanità Sistemica{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Americo Cicchetti{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Professore Ordinario di Organizzazione Aziendale Facoltà di Economia
                                Università Cattolica del Sacro Cuore; Direttore ALTEMS – Alta Scuola
                                di Economia e Management dei Sistemi Sanitari{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Gregorio Cosentino{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Presidente ASSD – Associazione Scientifica per la Sanità Digitale
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Claudio Cricelli{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Presidente SIMG – Società Italiana di Medicina Generale e delle cure
                                primarie{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Antonio D’Amore{' '}
                            </Heading>
                            <Text fontSize='sm'>Direttore Generale ASL Napoli 2 Nord</Text>{' '}
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Alessandro D’Arpino{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore S.C. Farmacia – Azienda Ospedaliera di Perugia{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Maurizio Dal Maso{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Consulente di direzione e cofounder HealtAbilty
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Luciano De Biase{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Professore Associato di Cardiologia, Dipartimento di Medicina
                                Clinica e Molecolare Università Sapienza; Direttore UOD Scompenso
                                Cardiaco Azienda Ospedaliero Universitaria Sant’Andrea, Roma
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Antonio D’Urso
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore Generale Azienda USL Toscana Sud Est
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Paola Facchin
                            </Heading>

                            <Text fontSize='sm'>
                                Responsabile del Coordinamento Regionale delle Malattie Rare,
                                Regione Veneto; Professore associato dell’Università degli Studi di
                                Padova
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Marcello Faviere
                            </Heading>
                            <Text fontSize='sm'>Magistrato Amministrativo – TAR Piemonte</Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Fabrizio Massimo Ferrara{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Coordinatore scientifico del Laboratorio sui sistemi informativi
                                sanitari ALTEMS – Alta Scuola di Economia e Management dei sistemi
                                Sanitari Università Cattolica del Sacro Cuore{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Paolo Francesconi{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Dirigente Osservatorio di Epidemiologia ARS – Agenzia Regionale di
                                Sanità della Toscana{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Francesco Gabbrielli{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore del Centro Nazionale per la Telemedicina e le Nuove
                                Tecnologie Assistenziali Istituto Superiore di Sanità{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Antonio Gaudioso{' '}
                            </Heading>
                            <Text fontSize='sm'>Segretario Generale di Cittadinanzattiva</Text>{' '}
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Marco Geddes da Filicaia{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Medico epidemiologo esperto di Sanità pubblica
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Fabrizio Gemmi{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Coordinatore Osservatorio della Qualità ed Equità ARS – Agenzia
                                Regionale di Sanità della Toscana{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Genesio Grassiri{' '}
                            </Heading>
                            <Text fontSize='sm'>Presidente & CEO Santec; CEO MED 2000 srl</Text>{' '}
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Paola Kruger{' '}
                            </Heading>
                            <Text fontSize='sm'>Paziente Esperto EUPATI</Text>{' '}
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Giancarlo Landini{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore Dipartimento Specialistiche Mediche Azienda USL Toscana
                                Centro{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Federico Lega{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Professore Ordinario di Economia, Politica e Management Sanitario,
                                Università degli Studi di Milano; Coordinatore del Centro di Ricerca
                                ed Alta Formazione in Health Administration (HEAD); Adjunct
                                professor SDA Bocconi School of Management{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Fabio Lena{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore Dipartimento del Farmaco Azienda USL Toscana Sud Est{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Lorenzo Leogrande{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Presidente nazionale AIIC – Associazione Italiana Ingegneri Clinici{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Velia Leone{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Avvocato esperto di appalti e PPP, in ambito UE e nazionale; Membro
                                della Faculty dello SDA Bocconi; Collaboratore middle nell’ambito
                                del Knowledge Group di Government, Health & Not for Profit{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Giuseppe Limongelli{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore Centro Coordinamento Malattie Rare, Regione Campania{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Stefano Maestrelli{' '}
                            </Heading>
                            <Text fontSize='sm'>Ingegnere Energy Manager</Text>{' '}
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Claudio Marinai{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Responsabile di settore Politiche del farmaco e dispositivi, Regione
                                Toscana{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Danilo Massai{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Presidente Ordine delle Professioni Infermieristiche
                                Interprovinciale Firenze Pistoia{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Teresita Mazzei{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Presidente Ordine dei Medici Chirurghi e degli Odontoiatri della
                                provincia di Firenze{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Giuseppe Maria Milanese{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Presidente Confcooperative Sanità e OSA – Operatori Sanitari
                                Associati{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Paolo Misericordia{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Responsabile Centro Studi e Area ICT della FIMMG – Federazione
                                Italiana dei Medici di Medicina Generale{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Marcello Pani{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore UOC Farmacia Policlinico Universitario Agostino Gemelli
                                IRCCS di Roma; Segretario Nazionale SIFO – Società Italiana di
                                Farmacia Ospedaliera e dei Servizi Farmaceutici{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Antonio Panti{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Componente Consulta Deontologica FNOMCeO – Federazione nazionale
                                degli ordini dei medici chirurghi e degli odontoiatri{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Stefania Papa{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Consigliere Ordine Nazionale Biologi; Delegato Nazionale per la
                                Sicurezza Alimentare Regione ToscanaUmbria{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Daniela Pedrini{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Presidente SIAIS – Società Italiana dell’Architettura e
                                dell’Ingegneria per la Sanità{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Nicolò Pestelli{' '}
                            </Heading>
                            <Text fontSize='sm'>Management Consultant in Healthcare</Text>{' '}
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Sergio Pillon
                            </Heading>

                            <Text fontSize='sm'>
                                Componente del Working Group Digital Health della European Public
                                Health Alliance; Direttore medico CIRM – Centro Internazionale Radio
                                Medico; Componente del gruppo di esperti dell’ISS Tecnologie
                                Sanitarie per il contrasto al Covid19{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Monica Piovi
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore Generale ESTAR – Ente di Supporto Tecnico Amministrativo
                                Regionale
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Gianluca Postiglione
                            </Heading>

                            <Text fontSize='sm'>
                                Responsabile Amministrazione Regulatory e Acquisti presso Acea Ato5
                                Spa
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Giuseppe Recchia{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                CoFounder e CEO, daVinci Digital – Milano; Vice Presidente
                                Fondazione Smith Kline – Verona{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Alberto Ronchi{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore dei Sistemi Informativi, Istituto Auxologico Italiano{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Eugenio Santoro{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore del Laboratorio di Informatica Medica Istituto di Ricerche
                                Farmacologiche Mario Negri IRCCS{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Annalisa Scopinaro{' '}
                            </Heading>
                            <Text fontSize='sm'>Presidente Uniamo</Text>{' '}
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Chiara Serpieri{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore Generale Azienda Sanitario Locale del Verbano Cusio
                                Ossola, Regione Piemonte{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Chiara Sgarbossa{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore dell’Osservatorio Innovazione Digitale in Sanità,
                                Politecnico di Milano{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Sandro Spinsanti{' '}
                            </Heading>{' '}
                            <Text fontSize='sm'>
                                Direttore Istituto Giano per le Medical Humanities di Roma{' '}
                            </Text>{' '}
                        </Box>{' '}
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Stefania Stefanelli{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Avvocato cassazionista, Esperta in Diritto Sanitario{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'></Heading>{' '}
                            <Text fontSize='sm'>Direttore Generale ASL Roma 1</Text>{' '}
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Domenica Taruscio{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Direttore Centro Nazionale Malattie Rare dell’Istituto Superiore di
                                Sanità{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Salvatore Torrisi
                            </Heading>

                            <Text fontSize='sm'>
                                Presidente FARE – Federazione delle Associazioni Regionali degli
                                Economi e Provveditori della Sanità{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Giuseppe Turchetti{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Professore Ordinario di Economia e gestione delle imprese Istituto
                                di management, Scuola Superiore Sant’Anna di Pisa{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Milena Vainieri
                            </Heading>

                            <Text fontSize='sm'>
                                Professore Associato in Economia e Gestione delle Imprese, Scuola
                                Superiore Sant’Anna di Pisa{' '}
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Alberto Villani{' '}
                            </Heading>

                            <Text fontSize='sm'>
                                Presidente SIP – Società Italiana di Pediatria
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Fabio Voller
                            </Heading>

                            <Text fontSize='sm'>
                                Coordinatore Osservatorio di Epidemiologia ARS – Agenzia Regionale
                                di Sanità della Toscana
                            </Text>
                        </Box>
                        <Box>
                            <Heading as='h3' color='brand.700' size='lg' mb='4'>
                                Alfredo Zuppiroli
                            </Heading>

                            <Text fontSize='sm'>
                                Presidente della Commissione Regionale di Bioetica, Regione Toscana
                            </Text>
                        </Box>
                    </SimpleGrid>*/}
                </>
            </Container>
        </>
    );
};

export default ComitatoScientifico;
