import styled from 'styled-components';
// import { createBreakpoint } from 'styled-components-breakpoint';
// import { styledBreakpoints } from '../../style/styledBreakpoints';
import { customTheme } from '../../style/customChakraTheme.js';
// const breakpoint = createBreakpoint(styledBreakpoints);

// background: ${customTheme.colors.brand[500]};
// background: ${props => (props.light && customTheme.colors.brand[100]) || (props.dark && customTheme.colors.brand[700]) || customTheme.colors.brand[500]};
// `url(./images/bg-login.png)`

export const CardContainer = styled.div`
    border-radius: 4px;
    overflow: hidden;
`;
export const CardThumb = styled.div`
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    padding-top: 56.25%;
    /* padding-top: ${props => (props.willbeLive && '106.5%') || '56.25%'}; */

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 72px;
        background-image: linear-gradient(
            180deg,
            #000000 0%,
            rgba(0, 0, 0, 0.98) 7%,
            rgba(0, 0, 0, 0.95) 14%,
            rgba(0, 0, 0, 0.88) 21%,
            rgba(0, 0, 0, 0.8) 27%,
            rgba(0, 0, 0, 0.71) 34%,
            rgba(0, 0, 0, 0.61) 40%,
            rgba(0, 0, 0, 0.5) 46%,
            rgba(0, 0, 0, 0.39) 53%,
            rgba(0, 0, 0, 0.29) 59%,
            rgba(0, 0, 0, 0.2) 67%,
            rgba(0, 0, 0, 0.12) 74%,
            rgba(0, 0, 0, 0.05) 82%,
            rgba(0, 0, 0, 0.02) 91%,
            rgba(0, 0, 0, 0) 100%
        );
        /* background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%); */
        opacity: 0.55;
        z-index: 1;
    }
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 235px;
        z-index: 1;
        opacity: 0.75;
        display: ${props => (props.willbeLive && 'block') || 'none'};
        /* background: linear-gradient(180deg, transparent 0%, #000 100%); */
    }

    > img,
    > picture,
    > svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
export const CardThumbData = styled.div`
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: auto;
    z-index: 2;
    color: #fff;
`;
export const CardThumbContent = styled.div`
    position: absolute;
    z-index: 2;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    color: #fff;
`;
export const CardThumbPlay = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 2;
    background: ${customTheme.colors.brand[500]};
    border-radius: 100%;
    color: #fff;
    padding: 0.5em;
    font-size: 24px;
    transition: transform 0.1s ease-in-out;

    &:hover {
        transform: translate3d(-50%, -50%, 0) scale(1.1);
    }
`;
