import React from 'react';
import { Heading, Text, Link, Box, Spinner } from '@chakra-ui/core';

import ScrollToTop from '../common/scrollToTop';
import { useStaticPageQuery } from './queries/staticPage';

const PrivacyPolicy = () => {
    const privacyData = useStaticPageQuery({ slug: 'privacy-policy' });
    if (privacyData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.700' />
            </Box>
        );
    if (privacyData.error) return <p>ERROR</p>;
    if (!privacyData.data) return <p>Not found</p>;
    return (
        <>
            <ScrollToTop />
            {privacyData.data.staticPage && (
                <>
                    <Heading as='h1' size='2xl' color='brand.700' textTransform='uppercase'>
                        {privacyData.data.staticPage.title}
                    </Heading>
                    <Box
                        className='mainContent'
                        dangerouslySetInnerHTML={{ __html: privacyData.data.staticPage.content }}
                    />
                </>
            )}
        </>
    );
};

export default PrivacyPolicy;
