import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// import logoFooter from '../../../images/logo-footer.svg';

import {
    IconInstagram,
    IconTwitter,
    IconFacebook,
    IconLinkedin,
    IconYoutube,
} from '../../../components/common/icons';

import { Container, Box, Image, Heading, Text, HStack, Link, Spinner } from '@chakra-ui/core';
import { FooterContainer, FooterGrid, FooterBottom, FooterLinks } from './styles';
import { useGetFooterQuery } from '../../query/getFooter';

const Footer = () => {
    const footerData = useGetFooterQuery();
    if (footerData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.700' />
            </Box>
        );
    if (footerData.error) return <p>ERROR</p>;
    if (!footerData.data) return <p>Not found</p>;
    const footer = footerData.data.getFooter;

    const getYear = () => {
        return new Date().getFullYear();
    };

    return (
        <FooterContainer>
            <Container maxWidth='1312px'>
                <FooterGrid>
                    <Box>
                        <Image src={footer.logoUrl} alt='Logo' />
                        <Text
                            mt='8'
                            fontSize='sm'
                            maxW='40ch'
                            dangerouslySetInnerHTML={{ __html: footer.description }}
                        />
                    </Box>
                    <Box>
                        <Heading as='h3' size='mdf' mb={['4', '6', '8']} textTransform='uppercase'>
                            Note legali
                        </Heading>
                        <FooterLinks>
                            {footer.footerLinks.map(footerLink => (
                                <li key={footerLink.title}>
                                    <Link as={RouterLink} to={{ pathname: `/${footerLink.url}` }}>
                                        {footerLink.title}
                                    </Link>
                                </li>
                            ))}
                        </FooterLinks>
                    </Box>
                    <Box>
                        <Heading as='h3' size='mdf' textTransform='uppercase' mb={['4', '6', '8']}>
                            Uffici Koncept
                        </Heading>
                        <Text fontSize='sm' fontWeight='500' letterSpacing='0.1em'>
                            {/* Via Giuseppe Tartini, <br /> 5b Firenze Italia */}
                            {footer.address}
                        </Text>
                    </Box>
                    <Box>
                        <Heading as='h3' size='mdf' textTransform='uppercase' mb={['4', '6', '7']}>
                            Contatti
                        </Heading>
                        <Text fontSize='sm' lineHeight='2.5' fontWeight='500' letterSpacing='0.1em'>
                            <Link href='mailto:segreteria@koncept.it'>
                                {/* segreteria@koncept.it */}
                                {footer.email}
                            </Link>
                            <br />
                            {/* telefono (+39) 055 357223 */}
                            {footer.phone1}
                            <br />
                            {/* mobile (+39) 333 9922717 */}
                            {footer.phone2}
                            <br />
                        </Text>
                    </Box>
                </FooterGrid>
                <FooterBottom>
                    <Box>
                        <Text fontSize='sm'>
                            &copy; {getYear()} KLive è una piattaforma eventi live proprietà di
                            Koncept s.r.l."
                        </Text>
                    </Box>
                    <Box>
                        <HStack
                            className='social-links'
                            spacing='2'
                            justify={['flex-start', 'flex-start', 'flex-end']}
                        >
                            <Text as='span' fontWeight='500' mr='4'>
                                Social networks
                            </Text>
                            <Link href='https://twitter.com/giuseppeorzati' isExternal>
                                <IconTwitter />
                            </Link>
                            <Link href='https://www.facebook.com/forumdellaleopolda/' isExternal>
                                <IconFacebook />
                            </Link>
                            <Link href='https://www.linkedin.com/in/giuseppeorzati/' isExternal>
                                <IconLinkedin />
                            </Link>
                            <Link
                                href='https://www.youtube.com/channel/UCWYsBC6XgSVYpLhjYWEyVwQ'
                                isExternal
                            >
                                <IconYoutube />
                            </Link>
                            <Link
                                href='https://www.instagram.com/forum_sistema_salute/?hl=it'
                                isExternal
                            >
                                <IconInstagram />
                            </Link>
                        </HStack>
                    </Box>
                </FooterBottom>
            </Container>
        </FooterContainer>
    );
};

export default Footer;
