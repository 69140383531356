import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Heading, Text, Button, Image, Box, Spinner } from '@chakra-ui/core';

import ScrollToTop from '../common/scrollToTop';
import { Helmet } from 'react-helmet-async';

// import { Hero, HeroContainer } from './styles';
import Header from '../layout/layoutMain/header/header';
import Footer from '../layout/layoutMain/footer/footer';
import VideoThumb from '../components/common/videoThumb';

import SliderHero from './components/sliderHero';
import SliderPatrocini from './components/sliderPatrocini';
import SliderExhibitors from './components/sliderExhibitors';
import SliderLiveShows from './components/sliderLiveShows';
import SliderWebinars from './components/sliderWebinars';

import imgCategories from '../images/img-categories.svg';

import { GridOneTwo, GridTwoOne, BackgroundPattern } from '../components/common/styles';

import imgMainStudio from '../images/bg-main-studio.jpg';
import MainStudio from './components/mainStudio';
import imgShare from '../images/share-home.jpg';
import { useGetPortalSettingsQuery } from '../layout/query/getPortalSettings';

const Homepage = () => {
    // const getYear = () => {
    //     return new Date().getFullYear();
    // };
    // let edition = getYear();
    const portalData = useGetPortalSettingsQuery();
    if (portalData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.700' />
            </Box>
        );
    if (portalData.error) return <p>ERROR</p>;
    if (!portalData.data) return <p>Not found</p>;
    const settings = portalData.data.getPortalSettings;
    let edition = settings.edition.name;
    return (
        <>
            {/* <Header bgBrand /> */}
            <ScrollToTop />
            <Helmet>
                <title>Global</title>
                <meta property='og:title' content='Global' />
                <meta
                    property='og:description'
                    content='Koncept srl è una società specializzata nell’organizzazione e nella gestione di eventi, nella comunicazione, nel marketing e nel fund raising strategico.'
                />
                <meta property='og:image' content={imgShare} />
            </Helmet>
            <Header />

            <SliderHero />

            <Box p={['24px 0', '24px 0', '48px 0']} overflow='hidden' bg='white'>
                <Container maxW='1752px'>
                    <GridOneTwo>
                        <div>
                            <Heading
                                as='h2'
                                size='xl'
                                mb='4'
                                color='brand.700'
                                textTransform='uppercase'
                            >
                                Patrocini
                            </Heading>
                            <Text mb='6' maxW='32ch' fontSize='sm'>
                                Con il patrocinio di
                            </Text>
                            <Button
                                as={RouterLink}
                                to={`/patrocini/${edition}`}
                                variant='brand'
                                size='lg'
                            >
                                Vedi tutto
                            </Button>
                        </div>
                        <div>
                            <SliderPatrocini edition={edition} />
                        </div>
                    </GridOneTwo>
                </Container>
            </Box>

            {/* <Box p={['24px 0', '24px 0', '48px 0']} overflow='hidden' bg='grey.100'>
                <Container maxW='1312px'>
                    <Box
                        position='relative'
                        // mt='8'
                        // mb='0'
                        borderRadius='5px'
                        bg='brand.500'
                        color='#fff'
                        p={['4', '8', '16']}
                    >
                        <BackgroundPattern />
                        <GridTwoOne style={{ position: 'relative', zIndex: '1' }}>
                            <MainStudio edition={edition} />
                        </GridTwoOne>
                    </Box>
                </Container>
            </Box> */}

            <Box p={['24px 0', '48px 0', '80px 0 32px']} overflow='hidden'>
                <Container maxW='1752px'>
                    <GridOneTwo>
                        <div>
                            <Heading
                                as='h2'
                                size='xl'
                                mb='4'
                                color='brand.700'
                                textTransform='uppercase'
                            >
                                {settings.liveNowTitle}
                            </Heading>
                            <Text maxW='32ch' fontSize='sm'>
                                {settings.liveNowDescription}
                            </Text>
                        </div>
                        <div>
                            <SliderLiveShows />
                        </div>
                    </GridOneTwo>
                </Container>
            </Box>

            {/* <Box p={['24px 0', '48px 0 24px', '80px 0 32px']} overflow='hidden'>
                <Container maxW='1752px'>
                    <Heading as='h2' size='xl' mb='4' color='brand.700' textTransform='uppercase'>
                        Plenaria
                    </Heading>
                    <Text maxW='32ch' fontSize='sm' mb={['4', '4', '8']}>
                        Evento in live direct, della durata di non più di 90’ (calcolare 15’ a
                        interlocutore, 5’ di presentazione e 5’ di conclusione)
                    </Text>
                    <SliderWebinars />
                </Container>
            </Box> */}

            <Box p={['24px 0 ', '48px 0 24px', '80px 0 32px']} overflow='hidden' bg='white'>
                <Container maxW='1752px'>
                    <Heading as='h2' size='xl' mb='4' color='brand.700' textTransform='uppercase'>
                        Webinar
                    </Heading>
                    <Text maxW='32ch' fontSize='sm' mb={['4', '4', '8']}>
                        In questa sezione trovi la lista di tutti gli eventi del Forum: in diretta
                        (LIVE NOW), conclusi (REPLAY) o programmati.
                    </Text>
                    <SliderWebinars edition={edition} />
                </Container>
            </Box>

            {/* <Box
                p={['24px 0', '48px 0 24px', '80px 0 32px']}
                bg='black'
                color='white'
                overflow='hidden'
            >
                <Container maxW='1752px'>
                    <Heading as='h2' size='xl' mb='4' color='brand.700' textTransform='uppercase'>
                        Design thinking
                    </Heading>
                    <Text maxW='50ch' fontSize='sm' mb={['4', '4', '8']}>
                        Output del Design Thinking, pubblicato e reso condivisibile con i
                        partecipanti, post evento.
                    </Text>
                    <SliderWebinars />
                </Container>
            </Box> */}

            {/* <Box p={['24px 0', '24px 0', '48px 0']} overflow='hidden'>
                <Container maxW='1752px'>
                    <GridOneTwo>
                        <div>
                            <Heading
                                as='h2'
                                size='xl'
                                mb='4'
                                color='brand.700'
                                textTransform='uppercase'
                            >
                                Exhibition Hall
                            </Heading>
                            <Text mb='6' maxW='32ch' fontSize='sm'>
                                Clicca per scoprire tutti i partner di questa edizione.
                            </Text>
                            <Button
                                as={RouterLink}
                                to={`/partner/${edition}`}
                                variant='brand'
                                size='lg'
                            >
                                Vedi tutto
                            </Button>
                        </div>
                        <div>
                            <SliderExhibitors edition={edition} />
                        </div>
                    </GridOneTwo>
                </Container>
            </Box> */}

            <Footer />
        </>
    );
};

export default Homepage;
