import styled from 'styled-components';
import { createBreakpoint } from 'styled-components-breakpoint';
import { styledBreakpoints } from '../style/styledBreakpoints';
import { customTheme } from '../style/customChakraTheme.js';
const breakpoint = createBreakpoint(styledBreakpoints);

// background: ${customTheme.colors.brand[500]};
// background: ${props => (props.light && customTheme.colors.brand[100]) || (props.dark && customTheme.colors.brand[700]) || customTheme.colors.brand[500]};
// `url(./images/bg-login.png)`
// ${breakpoint('md')`
//      width: 560px;
// `}

export const HeroContainer = styled.div`
    background-color: ${customTheme.colors.brand[700]};
    min-height: 100vh;
    overflow: hidden;
    position: relative;

    .swiper-container {
        height: 100vh;

        /* ${breakpoint('md')`
            height: 820px;
        `} */
        ${breakpoint('md')`
            height: 620px;
        `}
    }

    .swiper-slide {
        position: relative;
        height: 100%;
        color: #fff;
    }

    /* ${breakpoint('md')`
        min-height: 0;
        height: 820px;
    `} */
    ${breakpoint('md')`
        min-height: 0;
        height: 620px;
    `}
`;
export const HeroSlideBg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.$bg || customTheme.colors.brand[500]};

    img,
    svg,
    picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.45;
    }
`;
export const HeroSlideFirstContent = styled.div`
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .isDesktop {
        display: none;
    }
    ${breakpoint('md')`
        .isMobile {
            display: none;
        }
        .isDesktop {
            display: block;
        }
    `}
`;
export const HeroSlideContent = styled.div`
    position: absolute;
    bottom: 24px;
    left: 0;
    width: 100%;

    ${breakpoint('md')`
        bottom: 48px;
    `}
    ${breakpoint('lg')`
        bottom: 64px;
    `}
    /* ${breakpoint('xl')`
        bottom: 160px;
    `} */
    ${breakpoint('xl')`
        bottom: 72px;
    `}
`;
export const HeroContainerPagination = styled.div`
    display: block;
    pointer-events: none;
    width: 100%;
    bottom: 200px;
    position: absolute;
    z-index: 1;
`;
