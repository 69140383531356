import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Heading,
    Text,
    Box,
    Link,
    VStack,
    HStack,
    StackDivider,
    ButtonGroup,
    IconButton,
} from '@chakra-ui/core';

import { IconDownload } from '../../../components/common/icons';

export const FilesList = ({ files }) => {
    return (
        <>
            {files.length > 0 && (
                <Box bg='white' borderRadius='md' mb='6' p='4'>
                    <Heading as='h3' size='md' mb='4' textTransform='uppercase' color='black'>
                        Scarica file
                    </Heading>
                    <VStack
                        divider={<StackDivider borderColor='gray.200' />}
                        spacing={4}
                        align='stretch'
                        fontSize='lg'
                        color='brand.700'
                    >
                        {files.map(file => (
                            <HStack key={file.id} spacing='12px' align='center'>
                                <Link isExternal href={file.downloadUrl}>
                                    <IconButton
                                        bg='grey.100'
                                        _hover={{ bg: 'brand.500', color: 'white' }}
                                        color='brand.700'
                                        aria-label='Download'
                                        icon={<IconDownload />}
                                    />
                                </Link>
                                <Link isExternal href={file.downloadUrl} lineHeight='1.3'>
                                    <Text as='div' fontSize='lg' isTruncated maxW='22ch'>
                                        {file.name}
                                    </Text>
                                    <Text as='div' color='brand.700' fontSize='sm'>
                                        {file.fileSize}Mb
                                    </Text>
                                </Link>
                            </HStack>
                        ))}
                    </VStack>
                </Box>
            )}
        </>
    );
};

export default FilesList;
