import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, Image, Text, Button, Heading } from '@chakra-ui/core';

import { AuthContainer, AuthContainerMain, AuthContainerAction } from '../auth/styles';

import logoWide from '../images/logo-login-left.svg';
import logoStylish from '../images/logo-login.svg';

class LayoutAuth extends Component {
    render() {
        const { children } = this.props;
        return (
            <AuthContainer>
                <AuthContainerMain
                    // className={
                    //     this.props.location.pathname === '/registrati' ? 'register' : 'login'
                    // }
                    className={
                        this.props.location.pathname === '/registrati'
                            ? 'register'
                            : this.props.location.pathname === '/grazie'
                            ? 'register'
                            : this.props.location.pathname === '/login'
                            ? 'login'
                            : this.props.location.pathname === '/password-dimenticata'
                            ? 'forgotpw'
                            : this.props.location.pathname === '/email-sent'
                            ? 'forgotpw'
                            : 'forgotpw'
                    }
                >
                    <Box mt={[4, 8, 12]} ml={[4, 8, 24]} mr='1rem'>
                        <Link as={RouterLink} to='/'>
                            <Image src={logoWide} alt='Logo' />
                        </Link>
                    </Box>
                </AuthContainerMain>
                <AuthContainerAction>
                    <Box p={[4, 8, 24]}>
                        <Box d='flex' justifyContent='center' mb={['16px', '32px', '80px']}>
                            <Link as={RouterLink} to='/'>
                                <Image src={logoStylish} alt='Logo' />
                            </Link>
                        </Box>
                        {this.props.withTitle && (
                            <Heading
                                as='h1'
                                size='xl'
                                textAlign='center'
                                mb={[4, 4, 8]}
                                textTransform='uppercase'
                            >
                                {this.props.title}
                            </Heading>
                        )}
                        {children}
                    </Box>
                    {this.props.location.pathname === '/login' && (
                        <Box px={[4, 8, 8]} pb={[4, 8, 8]}>
                            <Text fontSize='sm' textAlign='center' mb='2'>
                                Non sei registrato?
                            </Text>
                            <Box textAlign='center'>
                                <Button
                                    as={RouterLink}
                                    to='/registrati'
                                    colorScheme='brand'
                                    size='xsm'
                                    variant='outline'
                                >
                                    Registrati
                                </Button>
                            </Box>
                        </Box>
                    )}
                    {this.props.location.pathname === '/registrati' && (
                        <Box px={[4, 8, 8]} pb={[4, 8, 8]}>
                            <Text fontSize='sm' textAlign='center' mb='2'>
                                Hai già un account?
                            </Text>
                            <Box textAlign='center'>
                                <Button
                                    as={RouterLink}
                                    to='/login'
                                    colorScheme='brand'
                                    size='xsm'
                                    variant='outline'
                                >
                                    login
                                </Button>
                            </Box>
                        </Box>
                    )}
                </AuthContainerAction>
            </AuthContainer>
        );
    }
}

LayoutAuth.propTypes = {};

export default LayoutAuth;
