import React from 'react';
import { Box, Spinner } from '@chakra-ui/core';
import Card from './interestedIn';
import { useGetInterestedInEventsGQLQuery } from '../query/getInterestedInEvents';

const InterestedInList = () => {
    const myInterestedInListData = useGetInterestedInEventsGQLQuery({ page: 1, limit: 20 });

    if (myInterestedInListData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.700' />
            </Box>
        );
    if (myInterestedInListData.error) return <p>ERROR</p>;
    if (!myInterestedInListData.data) return <p>Not found</p>;

    const interests = myInterestedInListData.data.getInterestedInEvents.eventDays;
    return (
        <>
            {interests && (
                <>
                    {interests && (
                        <>
                            {interests.map(({ events, date }, index) => {
                                return (
                                    <>
                                        {events.map(event => {
                                            return (
                                                <>
                                                    {event.status === 1 ? (
                                                        <Card
                                                            event={event}
                                                            willbeLive
                                                            key={event.id}
                                                        />
                                                    ) : (
                                                        <Card
                                                            willbeLive
                                                            event={event}
                                                            key={event.id}
                                                        />
                                                    )}
                                                    <br />
                                                </>
                                            );
                                        })}
                                    </>
                                );
                            })}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default InterestedInList;
