import React, { useContext } from 'react';
import {
    Avatar,
    Menu,
    MenuButton,
    MenuList,
    Box,
    Heading,
    Spinner,
    AvatarBadge,
} from '@chakra-ui/core';
import { useUserQuery } from '../config/userQuery';
import { AuthContext } from '../context/auth';
import { BellIcon } from '@chakra-ui/icons';
import NotificationList from '../notifications/components/notificationList';
import { useCountUnreadNotificationsQueryGQL } from '../notifications/queries/getUnreadNotifications';
import { useGetNotificationsQueryGQL } from '../notifications/queries/getNotifications';

export const NotificationAvatar = () => {
    const userData = useUserQuery();
    const { user } = useContext(AuthContext);
    const notificationCountData = useCountUnreadNotificationsQueryGQL();
    //const notificationsData = useGetNotificationsQueryGQL({ page: 1, limit: 20 });
    const [getNotifications, { loading, data }] = useGetNotificationsQueryGQL({
        page: 1,
        limit: 20,
    });
    if (notificationCountData.loading)
        return (
            <Box textAlign='center'>
                <Spinner color='brand.700' />
            </Box>
        );
    if (notificationCountData.error) return <p>ERROR</p>;
    if (!notificationCountData.data) return <p>Not found</p>;

    //console.log(notificationsData)

    // if (notificationsData.loading)
    //     return (
    //         <Box textAlign='center'>
    //             <Spinner color='brand.700' />
    //         </Box>
    //     );
    // if (notificationsData.error) return <p>ERROR</p>;
    // if (!notificationsData.data) return <p>Not found</p>;

    // const notifications = notificationsData.data.getNotifications;
    // console.log(notifications)

    const notificationsCount = notificationCountData.data.countUnreadNotifications;
    //console.log(notificationsCount)
    return (
        <div>
            {user && (
                <>
                    <Menu placement='bottom-end' autoSelect={false}>
                        <MenuButton onClick={() => getNotifications()}>
                            <Avatar bg='brand.300' size='sm' icon={<BellIcon fontSize='1.2rem' />}>
                                {/* <AvatarBadge border='0' bg='brand.700' boxSize='0.7em' /> */}
                                {notificationsCount !== '0' && (
                                    <AvatarBadge border='0' bg='brand.700' boxSize='0.7em' />
                                )}
                            </Avatar>
                        </MenuButton>
                        <MenuList bg='black' minWidth='240px' maxWidth='320px' border='none'>
                            <Heading
                                as='div'
                                px='12px'
                                pt='4px'
                                pb='12px'
                                color='brand.700'
                                size='md'
                                textTransform='uppercase'
                                borderBottom='2px solid #fff'
                            >
                                Notifications
                            </Heading>
                            <Box maxH='280px' style={{ overflow: 'auto' }}>
                                {data && <NotificationList notifications={data.getNotifications} />}
                            </Box>
                        </MenuList>
                    </Menu>
                </>
            )}
        </div>
    );
};
