import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Moment from 'react-moment';

import { useAuthToken } from '../config/auth';
import { Heading, Text, Button, Box, Link, Badge, Wrap } from '@chakra-ui/core';

import VideoThumb from '../components/common/videoThumb';

import { EventContainer } from '../programs/styles';
import AddEventToAgendaButton from './component/AddEventToAgenda';
import AddEventToInterestedInButton from './component/addEventToInterestedIn';

const Events = ({ event }) => {
    const [authToken] = useAuthToken();
    Moment.globalFormat = 'hh:mm A';

    return (
        <>
            <EventContainer key={event.id}>
                <Box>
                    {event.status !== 3 && (
                        <Text
                            as='p'
                            fontFamily='heading'
                            fontSize='2xl'
                            fontWeight='500'
                            color='brand.700'
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            <Moment unix format='HH:mm'>
                                {event.startAtTimestamp}
                            </Moment>
                            -
                            <Moment unix format='HH:mm'>
                                {event.endAtTimestamp}
                            </Moment>
                        </Text>
                    )}
                </Box>
                {event.status === 2 && (
                    <Box>
                        <VideoThumb
                            isLive
                            isBig={false}
                            videoUrl={event.liveVideoUrl}
                            imgUrl={event.coverPhotoUrl}
                            id={event.id}
                            authToken
                        />
                    </Box>
                )}
                {event.status === 3 && (
                    <Box>
                        <VideoThumb
                            isSimple
                            isBig={false}
                            imgUrl={event.coverPhotoUrl}
                            videoUrl={event.liveVideoUrl}
                            id={event.id}
                            authToken
                        />
                    </Box>
                )}
                {event.status === 1 && (
                    <Box>
                        <VideoThumb
                            isPhoto169
                            imgUrl={event.coverPhotoUrl}
                            isBig={false}
                            videoUrl={event.eventVideoUrl}
                            authToken
                        />
                    </Box>
                )}
                <Box>
                    <Wrap spacing='12px' align='center' mb='2'>
                        <Badge variant='brandDarkOutline' maxW='32ch' isTruncated>
                            {event.eventCategory.name}
                        </Badge>
                        {event.tag && (
                            <Text
                                as='span'
                                fontSize='sm'
                                fontWeight='500'
                                textTransform='uppercase'
                                color='brand.700'
                            >
                                #{event.tag}
                            </Text>
                        )}
                    </Wrap>
                    <Heading as='h2' size='xl' mb='4'>
                        <Link
                            as={RouterLink}
                            to={`/single-programma/${event.id}`}
                            color='brand.700'
                        >
                            {event.name}
                        </Link>
                    </Heading>
                    {event.eventSpeakers.length > 0 && (
                        <Text as='p' fontSize='lg' mb='4'>
                            Responsabile Scientifico:{' '}
                            <>
                                {event.eventSpeakers.map(speaker => (
                                    <Text
                                        className='has-comma'
                                        key={speaker.id}
                                        as='span'
                                        color='brand.700'
                                    >
                                        {speaker.name}
                                    </Text>
                                ))}
                            </>
                        </Text>
                    )}

                    <Text
                        as='p'
                        fontSize='sm'
                        mb='6'
                        className='lines-3'
                        dangerouslySetInnerHTML={{ __html: event.excerpt }}
                    />
                    {authToken ? (
                        <>
                            {event.status === 1 && (
                                <Wrap spacing='12px' align='center'>
                                    {!event.isInInterestedIn && (
                                        <AddEventToAgendaButton
                                            id={event.id}
                                            isInMyAgenda={event.isInMyAgenda}
                                            isInterestedIn={event.isInInterestedIn}
                                        />
                                    )}
                                    {!event.isInMyAgenda && (
                                        <AddEventToInterestedInButton
                                            id={event.id}
                                            isInterestedIn={event.isInInterestedIn}
                                            isInMyAgenda={event.isInMyAgenda}
                                        />
                                    )}
                                </Wrap>
                            )}{' '}
                            {event.status === 2 && (
                                <Button
                                    as={RouterLink}
                                    to={`/single-programma-preview/${event.id}`}
                                    size='xsm'
                                    variant='brand'
                                >
                                    Partecipa all'evento
                                </Button>
                            )}
                        </>
                    ) : (
                        <Text>
                            Per favore accedi o{' '}
                            <Link color='brand.700' as={RouterLink} to='/registrati' mr='1'>
                                registrati
                            </Link>
                            per aggiungere l'evento alla tua agenda
                        </Text>
                    )}
                </Box>
            </EventContainer>
        </>
    );
};

export default Events;
