import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Text, Image, Stack, Badge, Link } from '@chakra-ui/core';

import { PlayIcon } from './icons';
import { ThumbContainer, ThumbContent, ThumbPlay } from './styles';
import { useAuthToken } from '../../config/auth';
import VideoImageDefault from '../../images/video-gallery.png';

class VideoThumb extends Component {
    state = {
        videoShown: 0,
    };

    render() {
        const {
            isSimpleVideo,
            isPhoto,
            isPhoto169,
            isBig,
            isLive,
            videoUrl,
            imgUrl,
            title,
            videoDate,
            videoTime,
            id,
            isSlider,
            videoId,
        } = this.props;

        return (
            <ThumbContainer
                isBig={isBig}
                isPhoto={isPhoto}
                isPhoto169={isPhoto169}
                isSimpleVideo={isSimpleVideo}
            >
                <Image loading='lazy' src={imgUrl} alt={title} />
                <ThumbContent>
                    <Stack direction='row' align='center' spacing={3}>
                        {isLive ? (
                            <>
                                <Badge variant='brand'>Live now</Badge>
                                {videoDate ? (
                                    <Text as='span' fontSize='md' fontWeight='500'>
                                        {videoDate}
                                    </Text>
                                ) : null}
                                {videoTime ? (
                                    <Text as='span' fontSize='sm' color='brand.300'>
                                        ore {videoTime}
                                    </Text>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <Badge variant='brandOutline' zIndex='1000'>
                                    Replay
                                </Badge>
                                {videoDate ? (
                                    <Text as='span' fontSize='md'>
                                        Rivedi la diretta del {videoDate}
                                    </Text>
                                ) : null}
                            </>
                        )}
                    </Stack>
                    {/* <iframe
                        width='100%'
                        height='100%'
                        src={`https://www.youtube.com/embed/${videoUrl}`}
                        frameBorder='0'
                        //allowFullScreen
                        //showinfo='0'
                        controls='1'
                        autoPlay='0'
                    /> */}
                </ThumbContent>
                <>
                    {isSlider ? (
                        <ThumbPlay
                            as={RouterLink}
                            to={`/single-programma-preview-video/${videoUrl}`}
                        >
                            <PlayIcon />
                        </ThumbPlay>
                    ) : (
                        <ThumbPlay as={RouterLink} to={`/single-programma-preview/${id}`}>
                            <PlayIcon />
                        </ThumbPlay>
                    )}
                </>
            </ThumbContainer>
        );
    }
}

VideoThumb.propTypes = {
    isSimpleVideo: PropTypes.bool,
    isPhoto: PropTypes.bool,
    isPhoto169: PropTypes.bool,
    isBig: PropTypes.bool,
    isLive: PropTypes.bool,
    videoUrl: PropTypes.string,
    imgUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    videoDate: PropTypes.string,
    videoTime: PropTypes.string,
};
VideoThumb.defaultProps = {
    // isSimpleVideo: false,
    // isPhoto: false,
    // isBig: true,
    // isLive: true,
    // videoUrl: '/',
    imgUrl: `${VideoImageDefault}`,
    title: 'Lorem ipsum',
};

export default VideoThumb;
