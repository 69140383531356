import styled from 'styled-components';
import { createBreakpoint } from 'styled-components-breakpoint';
import { styledBreakpoints } from '../../style/styledBreakpoints';
import { customTheme } from '../../style/customChakraTheme.js';

import bgPatternPng from '../../images/bg-pattern.png';
import bgPatternWebp from '../../images/bg-pattern.webp';

const breakpoint = createBreakpoint(styledBreakpoints);

// background: ${customTheme.colors.brand[500]};
// background: ${props => (props.light && customTheme.colors.brand[100]) || (props.dark && customTheme.colors.brand[700]) || customTheme.colors.brand[500]};
// `url(./images/bg-login.png)`
// ${breakpoint('md')`
//      width: 560px;
// `}

/*---------------------------------------
    Sections
---------------------------------------*/
export const Section = styled.div`
    background: ${props =>
        (props.isWhite && '#fff') ||
        (props.isBrand && customTheme.colors.brand[500]) ||
        (props.isGrey && customTheme.colors.grey[100]) ||
        (props.isBlack && '#000') ||
        null};
    color: ${props =>
        (props.isWhite && '#000') ||
        (props.isBrand && '#fff') ||
        (props.isBlack && '#fff') ||
        null};
    padding: 24px 0;
    overflow: hidden;

    ${breakpoint('md')`
        padding: ${props => (props.isRoomy && '80px 0') || '48px 0'};
    `}
`;

/*---------------------------------------
    ExpandRight
---------------------------------------*/
export const ExpandRight = styled.div`
    ${breakpoint('lg')`
        width: calc(100% + 640px);
    `}
`;

/*---------------------------------------
    GridOneTwo
---------------------------------------*/
export const GridOneTwo = styled.div`
    display: grid;
    grid-gap: 1rem;

    > div {
        min-width: 0;
    }

    ${breakpoint('lg')`
        grid-gap: 3rem;
        grid-template-columns: 400px auto;
    `}
`;
/*---------------------------------------

---------------------------------------*/
export const GridTwoOne = styled.div`
    display: grid;
    grid-gap: 1rem;

    > div {
        min-width: 0;
    }

    ${breakpoint('lg')`
        grid-gap: 3rem;
        grid-template-columns: 2fr 1fr;
        `}
`;
export const BackgroundPattern = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-image: url(${bgPatternPng});
    border-radius: 5px;

    .webp & {
        background-image: url(${bgPatternWebp});
    }

    ${breakpoint('lg')`
        width: calc(100% + 768px);
    `}
`;

/*---------------------------------------
    Slides
---------------------------------------*/
export const SliderDots = styled.div`
    margin-top: 14px;
    .swiper-pagination-bullet {
        width: 12px;
        height: 20px;
        margin-right: 8px;
        position: relative;
        background: transparent none;
        border-radius: 0;
        opacity: 1;

        &::after {
            content: '';
            width: 100%;
            height: 2px;
            background: #c0c0c0;
            border-radius: 50em;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -1px;
        }

        ${breakpoint('lg')`
            width: 40px;
        `}
    }
    .swiper-pagination-bullet-active {
        &::after {
            background: ${customTheme.colors.brand[500]};
            height: 4px;
            margin-top: -2px;
        }
    }
`;
export const SliderDotsVert = styled.div`
    display: grid;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    .swiper-pagination-bullet {
        pointer-events: auto;
        width: 8px;
        height: 8px;
        background: #fff;
        opacity: 0.3;
        border-radius: 100%;
        justify-self: center;
        transition: transform 0.1s ease-in-out;
        will-change: transform;
    }
    .swiper-pagination-bullet-active {
        opacity: 1;
        transform: scale(1.5);
    }
`;
export const SliderPrev = styled.button`
    cursor: pointer;
    border: 0;
    padding: 0;
    background: transparent;
    width: 86px;
    height: 31px;
    color: ${customTheme.colors.brand[500]};

    &:focus {
        outline: 0;
    }
    &:active {
        position: relative;
        top: 1px;
    }

    img,
    svg {
        width: auto;
        height: auto;
    }

    &.swiper-button-disabled {
        color: ${customTheme.colors.grey[200]};
    }
`;
export const SliderNext = styled(SliderPrev)``;

/*---------------------------------------
    Logo links on home page
---------------------------------------*/
export const LogoLink = styled.a`
    border: 2px solid #fff;
    background: #fff;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    min-height: 160px;
    transition: all 0.1s ease-in-out;

    img,
    svg,
    picture {
        max-width: 220px;
        max-height: 76px;
        transition: all 0.1s ease-in-out;
        filter: ${props => props.$filter || null};
    }

    &:hover {
        border: 2px solid ${customTheme.colors.brand[500]};
        img,
        svg,
        picture {
            filter: none;
        }
    }
`;
export const LogoHolder = styled.div`
    border: 2px solid #fff;
    background: #fff;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    min-height: 160px;
    transition: all 0.1s ease-in-out;

    img,
    svg,
    picture {
        max-width: 220px;
        max-height: 76px;
        transition: all 0.1s ease-in-out;
        filter: ${props => props.$filter || null};
    }
`;

/*---------------------------------------
    Picture thumb
---------------------------------------*/
export const PhotoContainer = styled.a`
    display: inline-block;
    border-radius: 4px;
    overflow: hidden;
    padding-top: 56.25%;

    > img,
    > picture,
    > svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
    }
`;

/*---------------------------------------
    Video thumb component
---------------------------------------*/
export const ThumbDate = styled.span`
    display: inline-block;
    width: auto;
    color: #fff;
    background: ${customTheme.colors.brand[700]};
    padding: 0.3rem 0.5rem 0.5rem;
    border-radius: 5px;

    small,
    big {
        display: block;
        text-align: center;
        line-height: 1em;
    }
    big {
        font-size: ${props => (props.isBig && '64px') || '42px'};
        font-weight: 500;
    }
    small {
        font-size: ${props => (props.isBig && '20px') || '16px'};
    }
`;

export const ThumbContent = styled.div``;
export const ThumbPlay = styled.div``;
export const ThumbContainer = styled.div`
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    padding-top: ${props =>
        (props.isPhoto && '100%') || (props.isPhoto169 && '56.25%') || '56.25%'};

    ${ThumbDate} {
        position: absolute;
        top: 1rem;
        left: 1rem;
        z-index: 2;
        display: ${props =>
            (props.isPhoto && 'none') ||
            (props.isPhoto169 && 'none') ||
            (props.isSimpleVideo && 'none') ||
            null};
    }
    iframe,
    ${ThumbContent} {
        position: absolute;
        top: 1rem;
        left: 1rem;
        z-index: 2;
        color: #fff;
        display: ${props =>
            (props.isPhoto && 'none') ||
            (props.isPhoto169 && 'none') ||
            (props.isSimpleVideo && 'none') ||
            null};
    }

    ${ThumbPlay} {
        display: ${props => (props.isPhoto && 'none') || (props.isPhoto169 && 'none') || 'flex'};
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        z-index: 2;
        background: ${customTheme.colors.brand[500]};
        border-radius: 100%;
        color: #fff;
        padding: 0.5em;
        transition: transform 0.1s ease-in-out;
        font-size: ${props => (props.isBig && '50px') || '24px'};

        &:hover {
            transform: translate3d(-50%, -50%, 0) scale(1.1);
        }
    }

    &::before {
        display: ${props =>
            (props.isPhoto && 'none') ||
            (props.isPhoto169 && 'none') ||
            (props.isSimpleVideo && 'none') ||
            null};
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 72px;
        background-image: linear-gradient(
            180deg,
            #000000 0%,
            rgba(0, 0, 0, 0.98) 7%,
            rgba(0, 0, 0, 0.95) 14%,
            rgba(0, 0, 0, 0.88) 21%,
            rgba(0, 0, 0, 0.8) 27%,
            rgba(0, 0, 0, 0.71) 34%,
            rgba(0, 0, 0, 0.61) 40%,
            rgba(0, 0, 0, 0.5) 46%,
            rgba(0, 0, 0, 0.39) 53%,
            rgba(0, 0, 0, 0.29) 59%,
            rgba(0, 0, 0, 0.2) 67%,
            rgba(0, 0, 0, 0.12) 74%,
            rgba(0, 0, 0, 0.05) 82%,
            rgba(0, 0, 0, 0.02) 91%,
            rgba(0, 0, 0, 0) 100%
        );
        opacity: 0.55;
        z-index: 1;
    }

    > img,
    > picture,
    > svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
