import styled from 'styled-components';
import { createBreakpoint } from 'styled-components-breakpoint';
import { styledBreakpoints } from '../style/styledBreakpoints';
import { customTheme } from '../style/customChakraTheme.js';
import bgLoginPng from '../images/bg-login.png';
import bgLoginWebp from '../images/bg-login.webp';
import bgRegisterPng from '../images/bg-login.png';
import bgRegisterWebp from '../images/bg-login.webp';
import bgForgotpwPng from '../images/bg-login.png';
import bgForgotpwWebp from '../images/bg-login.webp';
const breakpoint = createBreakpoint(styledBreakpoints);
// background: ${customTheme.colors.brand[500]};
// background: ${props => (props.light && customTheme.colors.brand[100]) || (props.dark && customTheme.colors.brand[700]) || customTheme.colors.brand[500]};
// `url(../images/bg-login.png)`

export const AuthContainer = styled.div`
    min-height: 100vh;

    ${breakpoint('md')`
        display: grid;
        grid-template-columns: 1fr auto;
    `}
`;

export const AuthContainerMain = styled.div`
    display: none;

    ${breakpoint('md')`
        min-width: 0;
        height: 100vh;
        position: sticky;
        top:0;
        display: block;
        background-color: ${customTheme.colors.brown[500]};
        background-repeat: no-repeat;
        background-position: 33% 100%;
        &.register {
            background-image: url(${bgRegisterPng});
        }
        &.login {
            background-image: url(${bgLoginPng});
        }
        &.forgotpw {
            background-image: url(${bgForgotpwPng});
        }

        .webp & {
            &.register {
                background-image: url(${bgRegisterWebp});
            }
            &.login {
                background-image: url(${bgLoginWebp});
            }
            &.forgotpw {
                background-image: url(${bgForgotpwWebp});
            }
        }
    `}
`;

export const AuthContainerAction = styled.div`
    min-height: 100vh;
    display: grid;
    grid-gap: 24px;
    grid-template-rows: 1fr auto;
    background: ${customTheme.colors.brown[100]};

    ${breakpoint('md')`
        min-width: 0;
        width: 560px;
    `}
`;
