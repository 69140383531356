import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
} from '@chakra-ui/core';

const FaqAcordion = ({ faq }) => {
    return (
        <Accordion allowToggle>
            <AccordionItem bg='white' borderColor='grey.300'>
                <AccordionButton color='brand.700'>
                    <Box
                        flex='1'
                        textAlign='left'
                        color='brand.700'
                        fontSize='md'
                        fontFamily='body'
                    >
                        {faq.question}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    <Box
                        fontSize='14px'
                        fontFamily='body'
                        dangerouslySetInnerHTML={{ __html: faq.answer }}
                    />
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};

export default FaqAcordion;
