import React, { useContext } from 'react';
import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button,
    Textarea,
    Heading,
    Box,
} from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import { useEventContactMutation } from '../mutation/sendEventContact';

import { useUserQuery } from '../../config/userQuery';

import { AuthContext } from '../../context/auth';

const EventContactForm = ({ loading, eventName }) => {
    const userData = useUserQuery();
    const { user } = useContext(AuthContext);
    // const user = {};
    //console.log(!user);

    const { handleSubmit, errors, register } = useForm();
    const [eventContact, eventContactResults] = useEventContactMutation(errors);
    const disableForm = eventContactResults.loading || loading;
    const onSubmit = (values, errors) => {
        user.first_name !== undefined
            ? eventContact(
                  (values.eventName = eventName),
                  (values.userName = `${user.first_name} ${user.last_name}`),
                  (values.email = user.email),
                  values.question,
                  errors.email,
                  errors.question,
              )
            : eventContact(
                  (values.eventName = eventName),
                  values.userName,
                  values.email,
                  values.question,
                  errors.email,
                  errors.question,
                  errors.userName,
              );
    };
    return (
        <>
            <Heading as='h3' size='md' mb='4' textTransform='uppercase' color='black'>
                Invia una domanda
            </Heading>
            <form onSubmit={handleSubmit(onSubmit)}>
                {(!user || !user.first_name) && (
                    <>
                        <FormControl id='email' isInvalid={errors.email} mb='5'>
                            <FormLabel htmlFor='email'>Email</FormLabel>
                            <Input
                                name='email'
                                placeholder='example@xx.com'
                                borderColor='#999'
                                focusBordercolor='brand.700'
                                size='lg'
                                id='email'
                                ref={register({
                                    required: { value: true, message: 'Your email is required' },
                                })}
                            />
                            <FormErrorMessage>
                                {errors.email?.message || errors.email}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl id='userName' isInvalid={errors.email} mb='5'>
                            <FormLabel htmlFor='userName'>Nome</FormLabel>
                            <Input
                                name='userName'
                                placeholder='John'
                                borderColor='#999'
                                focusBordercolor='brand.700'
                                size='lg'
                                id='userName'
                                ref={register({
                                    required: { value: true, message: 'Your nome is required' },
                                })}
                            />
                            <FormErrorMessage>
                                {errors.userName?.message || errors.userName}
                            </FormErrorMessage>
                        </FormControl>
                    </>
                )}

                <FormControl mb='5' isInvalid={errors.question}>
                    <FormLabel htmlFor='name'>Domanda</FormLabel>
                    <Textarea
                        name='question'
                        placeholder='Your Question'
                        ref={register({
                            required: { value: true, message: 'Questo campo è obbligatorio' },
                        })}
                    ></Textarea>
                    <FormErrorMessage>
                        {errors.question?.message || errors.question}
                    </FormErrorMessage>
                </FormControl>
                <Button type='submit' size='lg' colorScheme='brand' disabled={disableForm}>
                    Invia Domanda
                </Button>
            </form>
        </>
    );
};
export default EventContactForm;
